import React, { createRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import API from '../API';
import Swal from 'sweetalert2';
import { DateFormat, DateThaiMonth, DateThai, NumberFormat, MobileFormat } from '../Utils/Function';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { Modal, Input } from "reactstrap";
import { OrderStatusLabel } from '../Utils/Status';
import parse from 'html-react-parser'

const DayReport = forwardRef((props, ref) => {

    const [_Date, setDate] = useState(new Date());
    const [_SumData, setSumDate] = useState(undefined);
    const [ModalState, setModalState] = useState(false);
    const [_OrderList, setOrderList] = useState([]);
    const [_DetailTitle, setDetailTitle] = useState('');
    const [_DataState, setDataState] = useState([]);
    const [_SumTotalTable, setSumTotalTable] = useState(0);
    const [_SearchDetail, setSearchDetail] = useState('');
    if (props.Date != undefined) setDate(props.Date);
    const { getValues, setValue, control, formState: { errors } } = useForm();

    useEffect(() => {
        (async () => {
            _GetDate();
        })();


        const _Interval = setInterval(() => {
            let _CurDate = new Date();
            if (getValues('Date') != undefined) {
                _CurDate = getValues('Date');
            }
            _GetDate(_CurDate);
        }, 300000);
        return () => {
            clearInterval(_Interval);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        Load(Date) {
            setDate(Date);
            _GetDate(Date);
        }
    }));


    const _GetDate = async (Date) => {
        if (Date === undefined) Date = _Date;
        API({ method: "post", url: "/Order/Report/Summary/Day", data: { Date: DateFormat(Date) }, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setSumDate(res.data.Data);
        }).catch(res => {
            return;
        });

    }
    const _AmountLabel = (Amount) => {

        if (Amount < 0) return false; else return true;
    }
    const _ShowDetail = async (val, Title) => {
        setDataState([]);
        if (val == undefined || val == null) return;
        setOrderList([]);
        setSumTotalTable(0);
        setDetailTitle(Title);
        setDataState(val);
        API({ method: "post", url: "/Order/Report", data: { OrderID: val }, headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setOrderList(res.data.Data);
            let _DepositAmountTotal = 0;
            res.data.Data.forEach((v) => {
                _DepositAmountTotal += v.DepositAmount;
            });
            setSumTotalTable(_DepositAmountTotal);
            setModalState(true);
        }).catch(res => {
            return;
        });
    }

    const _ExportExcel = async () => {
        API({ method: "post", url: "/Order/Report/Export", data: { OrderID: _DataState }, responseType: 'blob', headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            let _fileName = res.request.getResponseHeader('Content-Disposition').split(';')[1].split('=')[1];
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res.data);
            link.download = _fileName;
            link.click();

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message + ' [' + res.code + ']',
            })
        });;
    }
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <h4>Report ยอดขายประจำวัน :: {(_SumData != undefined ? DateThai(_SumData.CureDate) : '-')}  </h4>
                                </div>
                                <div className='col-lg-4 text-end'>
                                    <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={_Date} onChange={(date) => { setDate(date); _GetDate(date); setValue('Date', date) }} selectsStart startDate={_Date} maxDate={new Date()} />
                                </div>
                            </div>
                        </div>
                        <div className='card-body' >
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th className='text-end'></th>
                                                <th className='text-center fs-4'>{(_SumData != undefined ? DateThaiMonth(_SumData.CureDate) : '-')}</th>
                                                <th className='text-center fs-4'>{(_SumData != undefined ? DateThaiMonth(_SumData.NextDate) : '-')}</th>
                                                <th className='text-center fs-4'>อื่นๆ</th>
                                                <th className='text-center fs-4'>รวม</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className='text-end fs-4'>เป้าประจำวัน</th>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.CurTarget : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.NextTarget : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.OtherTarget : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? (_SumData.CurTarget + _SumData.NextTarget + _SumData.OtherTarget) : '0'))}</td>
                                            </tr>
                                            <tr>
                                                <th className='text-end fs-4'>ยอดขาย</th>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.CurAmount : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.NextAmount : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.OtherAmount : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? (_SumData.CurAmount + _SumData.NextAmount + _SumData.OtherAmount) : '0'))}</td>
                                            </tr>
                                            <tr>
                                                <th className='text-end fs-4'>รอชำระมัดจำ</th>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.CurApp : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.NextApp : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? _SumData.OtherApp : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? (_SumData.CurApp + _SumData.NextApp + _SumData.OtherApp) : '0'))}</td>
                                            </tr>
                                            <tr>
                                                <th className='text-end fs-4'>ยอดรวม</th>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? (_SumData.CurAmount+_SumData.CurApp) : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? (_SumData.NextAmount+_SumData.NextApp) : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? (_SumData.OtherAmount+_SumData.OtherApp) : '0'))}</td>
                                                <td className='text-center fs-4'>{NumberFormat((_SumData != undefined ? (_SumData.CurAmount+_SumData.CurApp +_SumData.NextAmount+ _SumData.NextApp +_SumData.OtherAmount+ _SumData.OtherApp) : '0'))}</td>
                                            </tr>
                                            <tr>
                                                <th className='text-end fs-3 text-danger'>ส่วนต่าง</th>
                                                <td className={(_SumData != undefined && _AmountLabel(_SumData.CurTotal) ? 'text-center text-success fs-3' : 'text-center fs-3 text-danger ')}>{NumberFormat((_SumData != undefined ? _SumData.CurTotal : '0'))}</td>
                                                <td className={(_SumData != undefined && _AmountLabel(_SumData.NextTotal) ? 'text-center text-success fs-3' : 'text-center fs-3 text-danger')}>{NumberFormat((_SumData != undefined ? _SumData.NextTotal : '0'))}</td>
                                                <td className={(_SumData != undefined && _AmountLabel(_SumData.OtherTotal) ? 'text-center text-success fs-3' : 'text-center fs-3 text-danger')}>{NumberFormat((_SumData != undefined ? _SumData.OtherTotal : '0'))}</td>
                                                <td className={(_SumData != undefined && _AmountLabel(_SumData.TotalAmount) ? 'text-center text-success fs-3' : 'text-center text-danger fs-3')}>{NumberFormat((_SumData != undefined ? _SumData.TotalAmount : '0'))}</td>
                                            </tr>
                                            <tr>
                                                <th className='text-end fs-4'>ใบเสนอราคา</th>
                                                <td className='text-center fs-4 user-select-none' onClick={() => { _ShowDetail((_SumData != undefined ? _SumData.CurOrderList : null), (_SumData != undefined ? DateThaiMonth(_SumData.CureDate) : '-')) }}>{NumberFormat((_SumData != undefined ? _SumData.CurOrder : '0'))}</td>
                                                <td className='text-center fs-4 user-select-none' onClick={() => { _ShowDetail((_SumData != undefined ? _SumData.NextOrderList : null), (_SumData != undefined ? DateThaiMonth(_SumData.NextDate) : '-')) }}>{NumberFormat((_SumData != undefined ? _SumData.NextOrder : '0'))}</td>
                                                <td className='text-center fs-4 user-select-none' onClick={() => { _ShowDetail((_SumData != undefined ? _SumData.OtherOrderList : null), "อื่นๆ") }}>{NumberFormat((_SumData != undefined ? _SumData.OtherOrder : '0'))}</td>
                                                <td className='text-center fs-4 user-select-none' onClick={() => { _ShowDetail((_SumData != undefined ? [].concat([..._SumData.CurOrderList], [..._SumData.NextOrderList], [..._SumData.OtherOrderList]) : null), "รวม") }}>{NumberFormat((_SumData != undefined ? (_SumData.CurOrder + _SumData.NextOrder + _SumData.OtherOrder) : '0'))}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-lg-12 text-end'>
                                    <h5>{(_SumData != undefined && DateFormat(_SumData.LastUpdate, true))}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
                <div className="modal-header">
                    <h5 className="modal-title">รายละเอียด ยอดขายประจำวัน :: {(_SumData != undefined ? DateThai(_SumData.CureDate) : '-')} [ {_DetailTitle} ]</h5>
                    <button type="button" className="btn btn-success btn-sm" style={{ 'marginLeft': '50px' }} onClick={() => { _ExportExcel(); }}>Export Excel</button>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModalState(false) }}></button>
                </div>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <Input
                                placeholder="ค้นหา"
                                onChange={(e) => setSearchDetail(e.target.value)}
                                value={_SearchDetail}
                            />
                        </div>
                        <div className='col-12 table-responsive'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th className="text-center textcenter">ลูกค้า</th>
                                        <th className="text-center textcenter">เบอร์โทร</th>
                                        <th className="text-center textcenter">วันที่เสนอราคา</th>
                                        <th className="text-center textcenter">วันที่จัดงาน</th>
                                        <th className="text-center textcenter">วันที่นัดชำระมัดจำ</th>
                                        <th className="text-center textcenter">วันที่ชำระมัดจำ</th>
                                        <th className="text-center textcenter">ประเภท</th>
                                        <th className="text-center textcenter">แพ็คเก็จ</th>
                                        <th className="text-center textright">ยอด</th>
                                        <th className="text-center textcenter">สถานะ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_OrderList?.filter(x =>
                                        x.CustomerName.toLowerCase().includes(_SearchDetail.toLowerCase().trim()) ||
                                        x.CustomerMobile.toLowerCase().includes(_SearchDetail.toLowerCase().trim()) ||
                                        x.PackageName.toLowerCase().includes(_SearchDetail.toLowerCase().trim()) ||
                                        x.PackageGroupName.toLowerCase().includes(_SearchDetail.toLowerCase().trim()) ||
                                        String(x.DepositAmount).toLowerCase().includes(_SearchDetail.toLowerCase().trim()) ||
                                        x.QuotationDate.toLowerCase().includes(_SearchDetail.toLowerCase().trim()) ||
                                        String(x.DepositDate).toLowerCase().includes(_SearchDetail.toLowerCase().trim())
                                    )?.map((v, i) => (
                                        <tr key={i}>
                                            <td className='text-left'>{v.CustomerName}</td>
                                            <td className='text-center'>{MobileFormat(v.CustomerMobile)}</td>
                                            <td className='text-center'>{DateFormat(v.QuotationDate)}</td>
                                            <td className='text-center'>{DateFormat(v.EventDate)}</td>
                                            <td className='text-center'>{DateFormat(v.DepositAppointment)}</td>
                                            <td className='text-center'>{DateFormat(v.DepositDate)}</td>
                                            <td className='text-center'>{v.PackageGroupName}</td>
                                            <td className='text-left'>{v.PackageName}</td>
                                            <td className='text-end'>{NumberFormat(v.DepositAmount)}</td>
                                            <td className='text-center'>{parse(OrderStatusLabel(v.Status))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="7"> <span className='fs-5'>จำนวนรายการ {_OrderList.length} รายการ</span></td>
                                        <td className='text-end'><span className='fs-5 text-danger'>{NumberFormat(_SumTotalTable)}</span></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>)

});

export default React.memo(DayReport)