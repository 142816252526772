import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';

import { createRoot } from 'react-dom/client';
import{CustomerInfoCard} from '../../Components/Customer';
import Swal from 'sweetalert2';
import API from '../../API';
import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const PageCallCenterWatting =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();
    const [_LastStatus,setLastStatus] = useState('');
    const ref = useRef();

    useEffect(() => {
        (async () => {
            _GetStatus();
        })();

        return ()=>{ }
    }, []);
    const _GetStatus = async()=>{
        
        API({ method: 'get', url: "/CallCenter/Status" }).then(res => {
            if (res.data.Status) {
                setLastStatus('');
                _UpdateStatus(res.data.Message);
                return;
            }
            setLastStatus(res.data.Message);
            window.setTimeout(()=>{
                _GetStatus();      
              },1000);
        }).catch(res=>{
            window.setTimeout(()=>{
                _GetStatus();      
              },2000);
        });
        
    }
    const _UpdateStatus = async(ContactLogID)=>{
        API({ method: 'delete', url: "/CallCenter/Status" }).then(res => {
            window.location.href = "./"+ContactLogID;
        
        }).catch(res=>{
            
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12 text-center'>
                        <h2>Watting</h2>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-12 text-center'>
                        <h2>{_LastStatus}</h2>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageCallCenterWatting)
