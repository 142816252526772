import React,{useImperativeHandle,forwardRef,useEffect, useState } from 'react';
import {Modal} from "reactstrap";

import Swal from 'sweetalert2';
import API from '../../API';
import {DateFormatJSON} from '../../Utils/Function';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TargetMonth = forwardRef((props,ref) =>{
    const [ModalState,setModalState] = useState(false);
    const [_TargetYear,setTargetYear] = useState(new Date().getFullYear());
    const [_TargetMonth,setTargetMonth] = useState(new Date().getMonth()+1);
    const [_Days,setDays] =useState(0);
    const [_AmountMonth,setAmountMonth] = useState(0);
    const [_AmountNext,setAmountNext] = useState(0);
    const [_AmountOther,setAmountOther] = useState(0);
    const [_Remark,setRemark] = useState('');


    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    useImperativeHandle(ref, () => ({
        Create(){
            setTargetYear(new Date().getFullYear());
            setTargetMonth(new Date().getMonth()+1);
            setDays(0);
            setAmountMonth(0);
            setAmountNext(0);
            setAmountOther(0);
            setRemark('');
            setModalState(true);
        },
        Show(TargetYear,TargetMonth){

            API({method : 'get',url:'/Setting/Target/Month?Year='+TargetYear+'&Month='+TargetMonth,headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }

                setTargetYear(res.data.Data.TargetYear);
                setTargetMonth(res.data.Data.TargetMonth1);
                setDays(res.data.Data.Days);
                setAmountMonth(res.data.Data.AmountMonth);
                setAmountNext(res.data.Data.AmountNext);
                setAmountOther(res.data.Data.AmountOther);

                setModalState(true);
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        }
    }));

    const _Save = async()=>{


        if(isNaN(_AmountMonth) || _AmountMonth === 0 || isNaN(_AmountNext) || _AmountNext === 0 || isNaN(_AmountOther) || _AmountOther ===0 ){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบยอด'
            });
            return;
        }
        if(isNaN(_Days) || _Days === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบวันทำงาน'
            });
            return;
        }
        const _reqObj = {
            Year:_TargetYear,
            Month:_TargetMonth,
            AmountMonth:_AmountMonth,
            AmountNext:_AmountNext,
            AmountOther:_AmountOther,
            Days:_Days
        }
        
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Setting/Target/Month/Save',data:_reqObj,headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                if(props.afterupdate != undefined){
                    try{
                        props.afterupdate();
                    }catch(e){
                        console.log(e);
                    }
                }
                setModalState(false);
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });
    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xs'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">เป้าประจำเดือน</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>

            <div className='modal-body'>
                <div className='row mb-3'>
                    <div className='col-lg-4 text-end fw-bold'>ปี</div>
                    <div className='col-lg-4'>
                        <select className='form-control text-center' defaultValue={_TargetYear} onChange={(e)=>{ setTargetYear(e.target.value);}}>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                            <option value={2025}>2025</option>
                        </select>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4 text-end fw-bold'>เดือน</div>
                    <div className='col-lg-6'>
                        <select className='form-control text-center' defaultValue={_TargetMonth} onChange={(e)=>{ setTargetMonth(e.target.value);}}>
                            <option value={1}>มกราคม</option>
                            <option value={2}>กุมภาพันธ์</option>
                            <option value={3}>มีนาคม</option>
                            <option value={4}>เมษายน</option>
                            <option value={5}>พฤษภาคม</option>
                            <option value={6}>มิถุนายน</option>
                            <option value={7}>กรกฎาคม</option>
                            <option value={8}>สิงหาคม</option>
                            <option value={9}>กันยายน</option>
                            <option value={10}>ตุลาคม</option>
                            <option value={11}>พฤศจิกายน</option>
                            <option value={12}>ธันวาคม</option>
                        </select>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4 text-end fw-bold'>วันทำงาน</div>
                    <div className='col-lg-4'>
                        <input type="text" className="form-control  text-end" placeholder="วันทำงาน" autoComplete='off' value={_Days} onChange={(e)=>{setDays(e.target.value);}}  />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4 text-end fw-bold'>ยอดประจำเดือน</div>
                    <div className='col-lg-4'>
                        <input type="text" className="form-control  text-end" placeholder="ยอดประจำเดือน" autoComplete='off' value={_AmountMonth} onChange={(e)=>{setAmountMonth(e.target.value);}}  />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4  text-end fw-bold'>ยอดเดือนถัดไป</div>
                    <div className='col-lg-4'>
                        <input type="text" className="form-control  text-end" placeholder="ยอดเดือนถัดไป" autoComplete='off' value={_AmountNext} onChange={(e)=>{setAmountNext(e.target.value);}} />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4  text-end fw-bold'>ยอดอื่นๆ</div>
                    <div className='col-lg-4'>
                        <input type="text" className="form-control text-end" placeholder="ยอดอื่นๆ" autoComplete='off' value={_AmountOther} onChange={(e)=>{setAmountOther(e.target.value);}} />
                    </div>
                </div>
                
                <div className='row mt-4'>
                    <div className='col-lg-12 text-center'>
                        <button type="button" className='btn btn-success btn-lg' onClick={()=>{_Save();}}>บันทึก</button>
                    </div>
                </div>
            </div>
        </Modal>
        </>)
        
});

export default React.memo(TargetMonth)