import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, DateThaiFormat, FileURL } from '../../Utils/Function';
import {QuotationStatusLabel,MemberLevelName} from '../../Utils/Status'
import {QuotationInfoCard,QuotationCreate} from '../../Components/Quotation'
import {CustomerCreate,CustomerList} from '../../Components/Customer';
import {UploadCard,FilePreviewCard} from '../../Components';
import parse from 'html-react-parser'


const PageChatList = forwardRef((props,ref) =>{
    const {reset, getValues,setValue } = useForm();
    
    const [_ChatMemberList,setChatMemberList] = useState([]);
    const [_ContactLogID,setContactLogID] = useState(undefined);
    const [_ContactLog,setContactLog] = useState(undefined);
    const [_Customer,setCustomer] = useState(null);
    const [_CustomerID,setCustomerID] = useState(null);

    const [_ChatMessageList,setChatMessageList] = useState([]);
    const [_Message,setMessage] = useState('');
    const [_QuotationList,setQuotationList] = useState([]);
    const [_FileList,setFileList] = useState([]);

    const _CustomerCreate = useRef();
    const _CustomerList = useRef();
    const [_SearchCustomer,setSearchCustomer] = useState(false);
    const [_CustomerNote,setCustomerNote] = useState('');

    const [_SearchQuotation,setSearchQuotation] = useState(false);
    const _QuotationCreate = useRef();
    const _QuotationInfoCard = useRef();
    const _MessageBoxRef = useRef(null);
    const _UploadRef = useRef();
    const _FilePreviewRef = useRef();

    let _IntervalMessage = undefined;

    useEffect(() => {
        let _IntervalList = undefined;
        (async () => {
            setChatMemberList([]);
            setChatMessageList([]);
            setFileList([]);
            setContactLogID(undefined);
            setContactLog(undefined);
            setCustomer(null);
            setSearchCustomer(false);
            setValue('ContactLogID',undefined);
            setMessage('');
            setCustomerNote('');
            _LoadChat();
            _IntervalList = setInterval(() => {
                _LoadChat();
            }, 10000);
            _IntervalMessage = setInterval(()=>{
                if(getValues('ContactLogID') === undefined ||getValues('ContactLogID') === null) return;
                _LoadChatMessage(getValues('ContactLogID'),false);
            },5000)
        })();

        return ()=>{
            if(_IntervalList != undefined) clearInterval(_IntervalList);
            if(_IntervalMessage != undefined) clearInterval(_IntervalMessage);

         }
    }, []);
    const _MessageBoxScrollToEnd = ()=>{
        if((_MessageBoxRef.current?.scrollHeight - _MessageBoxRef.current?.clientHeight) >=1){
            _MessageBoxRef.current?.lastElementChild?.scrollIntoView();
        }
        
    }
    const _SortingMember = (ContactLogID,resData)=>{
        let _ItemAR = [];
        resData.map((v,i)=>{
            if(v.ContactLogID === ContactLogID){
                _ItemAR.push(v);
            }
        });

        resData.map((v,i)=>{
            if(v.ContactLogID != ContactLogID){
                _ItemAR.push(v);
        }
        });
        setChatMemberList(_ItemAR);
    }
    const _LoadChat = async()=>{
        
        API({method : "get",url:"/CallCenter/Chat/List"}).then(res=>{
            if (res.data.Status) {
                if(getValues('ContactLogID') != undefined){
                    _SortingMember(getValues('ContactLogID'),res.data.Data);
                }else{
                    setChatMemberList(res.data.Data);
                }
            }
        }).catch(res => {
            setChatMemberList([]);
        });
    }

    const _OpenContactLog = async(ContactLogID)=>{
        if(ContactLogID === undefined){
            ContactLogID = _ContactLogID;
        }else{
            setContactLogID(ContactLogID);
            setValue('ContactLogID',ContactLogID);
        }

        _SortingMember(ContactLogID,_ChatMemberList);
        setContactLog(undefined);
        setCustomer(null);
        setSearchCustomer(false);
        setChatMessageList([]);
        setQuotationList([]);
        setCustomerID(undefined);
        setFileList([]);
        Swal.showLoading();
        API({method : "post",url:"/CallCenter/"+ContactLogID}).then(res=>{
            if (res.data.Status) {
                setContactLog(res.data.Data);
                if(res.data.Data.Customer != null){
                    setCustomer(res.data.Data.Customer);
                    setCustomerID(res.data.Data.Customer.CustomerID);
                    _LoadQuotationList(res.data.Data.Customer.CustomerID);
                }
                _LoadChatMessage(ContactLogID,true);
                //_LoadChat();
            }
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }

    const _LoadChatMessage = async(ContactLogID,ResetMessage = false)=>{
        if(ResetMessage){
            setMessage('');
            setFileList([]);
        }
       
        API({method : "get",url:"/CallCenter/Chat/"+ContactLogID+"/Message"}).then(res=>{
            if (res.data.Status) {
                setChatMessageList(res.data.Data);
                setTimeout(()=>{_MessageBoxScrollToEnd();},50);
            }
        }).catch(res => {
            setChatMessageList([]);
        });
    }

    const _SendMessage = async()=>{
        if(_Message.length === 0 && _FileList.length === 0) return;
        let _reqObj = {Message:_Message,MessageType:1}
        if(_FileList.length > 0){
            _reqObj.Attachments = [];
            _FileList.map((v,i)=>{
                _reqObj.Attachments.push(v.FileID);
            });
        }
        Swal.showLoading();
        API({method : "post",url:"/CallCenter/Chat/"+_ContactLogID+"/Message",data:_reqObj}).then(res=>{
            if (res.data.Status) {
                _LoadChatMessage(_ContactLogID,true);
            }
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }

    const _OpenMessage = async(ChatMessageID)=>{
        API({method : "get",url:"/CallCenter/Chat/Message/"+ChatMessageID}).then(res=>{
            if (res.data.Status) {
                console.log(res.data);
            }
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }

    const _LoadQuotationList = async(CustomerID)=>{
            API({method : "get",url:"/CallCenter/Quotation/"+CustomerID+"?Limit=5"}).then(res=>{
                if (res.data.Status) {
                    setQuotationList(res.data.Data);
                }
    
            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
               
            });
    }

    const _UpdateCustomer = async(Customer)=>{
        if(Customer == undefined || Customer == null) return;
        setCustomer(Customer);
        setCustomerID(Customer.CustomerID);
        API({method : "post",url:"/CallCenter/"+_ContactLogID+"/"+Customer.CustomerID}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
            }
            _LoadChat();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
           
        });
        _LoadQuotationList(Customer.CustomerID);
    }

    const _UploadFile = (FileObj)=>{
        let _AR = [... _FileList];
        _AR.push(FileObj);
        setFileList(_AR);
        console.log(_AR);
    }

    const _UploadDelete = (FileObj)=>{
        
        let _AR = [];
        _FileList.map((v,i)=>{
            if(FileObj.FileID === v.FileID) return;
            _AR.push(v);
        })
        setFileList(_AR);
    }

    const _renderMessage = (MessageObj)=>{
        if(MessageObj.MessageType == 1) return parse(MessageObj.Message);
        if(MessageObj.Attachments === undefined || MessageObj.Attachments === null) return '';
        let _Html = '<center>';
        //console.log(MessageObj);
        MessageObj.Attachments.map((v,i)=>{
            if(String(v.FileType).includes('image/')){
                _Html += '<a href="'+FileURL(v)+'" target="_blank"><img class="formm-control" src="'+FileURL(v)+'/Preview" style="max-height:100px;max-width:100px;"></a>'
            }
        });
        _Html +='</center>';
        return parse(_Html);
    }
const _SaveCustomerNote = async()=>{
        API({method : "post",url:"/CallCenter/Customer/Note/"+_CustomerID,data:{Note:_CustomerNote},headers:{'content-type':'application/x-www-form-urlencoded'}}).then(res=>{
            if (!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message,
                });
                return;
            }else{
                Swal.fire({
                    icon: 'success',
                    title: 'บันทึกสำเร็จ',
                    timer: 2000,
                    buttons: false,
                    showConfirmButton: false
                });
            }

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
               <div className='row'>
                    <div className='col-3'>
                        <div className='card'>
                            <div className='card-body' style={{minHeight:'200px',maxHeight:'calc(100vh - 130px)',overflowX:'auto'}}>
                                {_ChatMemberList.map((v,i)=>(
                                    <div className='row mt-2 mb-2' key={v.ContactLogID}>
                                        <div className='col-12'>
                                            <div className='card' role="button" >
                                                <div className= {(_ContactLogID === v.ContactLogID ? "card-body chat-Item-Active" : "card-body chat-Item")}>
                                                    <div className='row' onClick={()=>{_OpenContactLog(v.ContactLogID)}}>
                                                        <div className='col-2 text-center'>
                                                            <div className='row'>
                                                                <div className='col-12'><img src={v.ProfileImage} className='rounded-circle' style={{maxHeight:'50px'}}/></div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'><span style={{fontSize:'12px'}}>{v.ChannelName}</span></div>
                                                            </div>
                                                        </div>
                                                        <div className='col-10'>
                                                            <h5>{v.ProfileName}</h5>
                                                            {(v.Customer != undefined && v.Customer != null ? <><span className='text-end'><b>{v.Customer.CustomerCode}</b> {v.Customer.Name}</span></>:"" )}
                                                            <br/>
                                                            <span className='text-end'>{DateThaiFormat(v.LastUpdate,true)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='col-6' hidden={(_ContactLog === undefined ? true : false)}>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between align-items-center'>
                                <h3 className='card-title'>{(_ContactLog != undefined? _ContactLog.ContactRef: "-")}</h3>
                                <div className='btn btn-group btn-group-lg'>
                                    {(_ContactLog != undefined && _ContactLog.StartDate === null? <button type="button" className='btn btn-success text-white'>เริ่ม</button> : "")}
                                    {(_ContactLog != undefined && _ContactLog.StartDate != null && _ContactLog.EndDte === null? <button type="button" className='btn btn-warning text-white'>ปิดงาน</button> : "")}
                                </div>
                            </div>
                            <div ref={_MessageBoxRef} className='card-body' style={{minHeight:'200px',maxHeight:'calc(100vh - 360px)',overflowX:'auto'}}>
                                {_ChatMessageList.map((v,i)=>(
                                    (v.MessageMode == 2 ?
                                    <div className='row mt-2 mb-2 justify-content-start' key={v.ChatMessageID}>
                                        <div className='col-6 text-start'>
                                            <div className='card' role="button" onDoubleClick={()=>{_OpenMessage(v.ChatMessageID)}}>
                                                <div className='card-body' style={{whiteSpace:'pre-line'}}>
                                                {_renderMessage(v)}
                                                </div>
                                                <div className='card-footer'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <span>{DateThaiFormat(v.CreateDate,true)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='row mt-2 mb-2 justify-content-end' key={v.ChatMessageID}>
                                        <div className='col-6 text-start'>
                                            <div className='card' role="button" onDoubleClick={()=>{_OpenMessage(v.ChatMessageID)}}>
                                                <div className='card-body text-end' style={{whiteSpace:'pre-line'}}>
                                                {_renderMessage(v)}
                                                </div>
                                                <div className='card-footer'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <span>{DateThaiFormat(v.CreateDate,true)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                    
                                ))}
                            </div>
                            <div className='card-footer'>
                                <div className='row'>
                                    <div className='col-9'>
                                        <textarea className='form-control' value={_Message} onChange={(e)=>{setMessage(e.target.value)}} style={{minHeight:'80px'}}></textarea>
                                    </div>
                                    <div className='col-3 text-end'>
                                        <button className='btn btn-success btn-lg' disabled={_Message.length === 0 && _FileList.length === 0 ? true:false} onClick={()=>{_SendMessage()}} style={{minHeight:'80px'}}>ส่งข้อความ</button>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-9'>
                                        {(_FileList != null && _FileList.length > 0 ? <>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>ชื่อไฟล์</th>
                                                        <th className='text-center'>#</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_FileList.map((v,i)=>(
                                                        <tr key={i}>
                                                            <td>
                                                                <a href='#' style={{textDecoration:'none'}} onClick={(e)=>{_FilePreviewRef.current.Open(v);}}>{v.FileName}</a>
                                                            </td>
                                                            <td className='text-center'>
                                                                <div className='btn btn-group btn-group-sm'>
                                                                    <button className='btn btn-danger' type='button' onClick={()=>{_UploadDelete(v);}}>ลบ</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>:"")}
                                    </div>
                                    <div className='col-3 text-end'>
                                        <button className='btn btn-warning btn-sm text-white' onClick={()=>{_UploadRef.current.Open('CHAT',_ContactLogID,undefined,(e)=>{ _UploadFile(e); })}}>แนบไฟล์</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'  hidden={(_ContactLog === undefined ? true : false)}>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between align-items-center bg-primary text-white '>
                                        <h4 className='card-title'>ข้อมูลลูกค้า</h4>
                                        <div className='btn btn-group btn-group-sm'>
                                            <button type="button" className='btn btn-sm btn-warning text-white' onClick={()=>{_CustomerList.current?.Show()}}>ค้นหา</button>
                                            <button type="button" className='btn btn-sm btn-danger text-white' onClick={()=>{_CustomerCreate.current?.Show(_ContactLogID)}}>สร้าง</button>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-3 text-end'><label className="form-label fw-bold">รหัสลูกค้า</label> </div>
                                                    <div className='col-9'>{(_Customer != null?_Customer.CustomerCode:"")}</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-3 text-end'><label className="form-label fw-bold">ชื่อลูกค้า</label> </div>
                                                    <div className='col-9'>{(_Customer != null?_Customer.Name:"")}</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-3 text-end'><label className="form-label fw-bold">เบอร์โทร</label> </div>
                                                    <div className='col-9'>{(_Customer != null?_Customer.Mobile:"")}</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-3 text-end'><label className="form-label fw-bold">ชื่อเล่น</label> </div>
                                                    <div className='col-9'>{(_Customer != null?_Customer.NickName:"")}</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-3 text-end'><label className="form-label fw-bold">ระดับลูกค้า</label> </div>
                                                    <div className='col-9'>{(_Customer != null?MemberLevelName(_Customer.MemberLevel):"")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2' hidden={(_Customer != null ?false : true)}>
                                            <div className='col-12'>
                                                <textarea className='form-control' style={{minHeight:'150px'}} value={_CustomerNote} onChange={(e)=>{setCustomerNote(e.target.value);}}></textarea>
                                            </div>
                                        </div>
                                        <div className='row mt-2' hidden={(_Customer != null ?false : true)}>
                                            <div className='col-6'>
                                                                
                                            </div>
                                            <div className='col-6 text-end'>
                                                <button className='btn btn-danger' onClick={()=>{_SaveCustomerNote()}}>บันทึก</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <div className='card'>
                                <div className='card-header d-flex justify-content-between align-items-center bg-info text-white '>
                                    <h4 className='card-title'>ข้อมูลใบเสนอราคา</h4>
                                    <div className='btn btn-group btn-group-sm'>
                                        <button type="button" className='btn btn-sm btn-warning text-white' onClick={()=>{setSearchQuotation(true);}}>ค้นหา</button>
                                        <button type="button" className='btn btn-sm btn-danger' disabled={_Customer == null ? true:false} onClick={()=>{ _QuotationCreate.current?.Show(_Customer);}}>สร้าง</button>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12 table-responsive'>
                                            <table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>เลขที่ใบเสนอราคา</th>
                                                        <th className='text-center'>วันที่จัดงาน</th>
                                                        <th className='text-center'>ยอดรวม</th>
                                                        <th className='text-center'>สถานะ</th>
                                                        <th className='text-center'>#</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_QuotationList.map((v,i)=>( 
                                                        <tr key={i}>
                                                            <td className='text-center'>{v.QuotationCode}</td>
                                                            <td className='text-center'>
                                                                {v.Events.map((ev,ei)=>(
                                                                    <div className='row' key={ei}>
                                                                        <div className='col-12'>{DateThaiFormat(ev.EventDate)}</div>
                                                                    </div>
                                                                ))}
                                                            </td>
                                                            <td className='text-end'>{NumberFormat(v.Profile.TotalAmount)}</td>
                                                            <td className='text-center'>
                                                                {parse(QuotationStatusLabel(v.Profile.Status))}
                                                            </td>
                                                            <td className='text-center'>
                                                                <div className='btn-group btn-group-sm'>
                                                                    <button type='button' className='btn btn-info text-white' onClick={()=>{_QuotationInfoCard.current?.Show(v.QuotationID)}}>Info</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
            <QuotationInfoCard ref={_QuotationInfoCard} update={()=>{_LoadQuotationList(_CustomerID);}}/>
            <QuotationCreate ref={_QuotationCreate} update={()=>{_LoadQuotationList(_CustomerID);}}/>
            <CustomerCreate ref={_CustomerCreate} update={(Customer)=>{_UpdateCustomer(Customer);}}/>
            <CustomerList ref={_CustomerList} update={(Customer)=>{_UpdateCustomer(Customer);}}/>
            <UploadCard ref={_UploadRef} />
            <FilePreviewCard ref={_FilePreviewRef} />
        </React.Fragment>
        </>)
        
});

export default React.memo(PageChatList)