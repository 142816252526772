import React,{useRef,useState,useEffect} from 'react';
import '../../Assets/CSS/Horapa.css';
import {DateFormat,NumberFormat} from '../../Utils/Function';
import API from '../../API';
import {TargetDayCard} from '../../Components';

const PageTargetDayList =()=> {

    const [TargetDayList,setTargetDayList] = useState([]);
    const _TargetDayRef = useRef();
    const [UpdateTime,setUpdateTime] = useState(new Date());
    const [TargetYear,setTargetYear]= useState(new Date().getFullYear());
    const [TargetMonth,setTargetMonth]= useState(new Date().getMonth()+1);
    useEffect(() => {
        (async () => {
            _GetTargetList();
        })();
        

    }, []);

    const _GetTargetList = async()=>{
        
        API({method : "get",url:"/Setting/Target/Day/List?Year="+TargetYear+"&Month="+TargetMonth}).then(res=>{
            if (res.status !== 200) {
                setTargetDayList([]);
                return;
            }

            if (!res.data.Status) {
                setTargetDayList([]);
                return;
            }
            setTargetDayList(res.data.Data);
        }).catch(res => {
            setTargetDayList([]);
            return;
        });
    }

    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>รายการเป้าประจำวัน</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row justify-content-end'>
                                    
                                    <div className='col-2 col-lg-2 mt-2'>
                                        <select className='form-control text-center' defaultValue={(UpdateTime.getMonth()+1)} onChange={(e)=>{ setTargetMonth(e.target.value);}}>
                                            <option value={1}>มกราคม</option>
                                            <option value={2}>กุมภาพันธ์</option>
                                            <option value={3}>มีนาคม</option>
                                            <option value={4}>เมษายน</option>
                                            <option value={5}>พฤษภาคม</option>
                                            <option value={6}>มิถุนายน</option>
                                            <option value={7}>กรกฎาคม</option>
                                            <option value={8}>สิงหาคม</option>
                                            <option value={9}>กันยายน</option>
                                            <option value={10}>ตุลาคม</option>
                                            <option value={11}>พฤศจิกายน</option>
                                            <option value={12}>ธันวาคม</option>
                                        </select>
                                    </div>
                                    <div className='col-1 col-lg-1 mt-2'>
                                        <select className='form-control text-center' defaultValue={UpdateTime.getFullYear()} onChange={(e)=>{ setTargetYear(e.target.value);}}>
                                            <option value={2023}>2023</option>
                                            <option value={2024}>2024</option>
                                            <option value={2025}>2025</option>
                                        </select>
                                    </div>
                                    <div className='col-2 col-lg-2 text-end'>
                                        <div className='btn btn-group'>
                                            <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>{_GetTargetList();}}>Search</button>
                                            <button type='button'  className='btn btn-primary text-white' onClick={()=>{_TargetDayRef.current?.Create()}}>สร้างรายการ</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>วันที่</th>
                                                    <th className='text-center'>ยอดประจำเดือน</th>
                                                    <th className='text-center'>ยอดเดือนถัดไป</th>
                                                    <th className='text-center'>ยอดอื่นๆ</th>
                                                    <th className='text-center'>หมายเหตุ</th>

                                                    <th className='text-center'>แก้ไขล่าสุด</th>
                                                    <th className='text-center'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {TargetDayList.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td className='text-center'>{DateFormat(v.TargetDate,false)}</td>
                                                        <td className='text-center'>{NumberFormat(v.AmountMonth)}</td>
                                                        <td className='text-center'>{NumberFormat(v.AmountNext)}</td>
                                                        <td className='text-center'>{NumberFormat(v.AmountOther)}</td>
                                                        <td className='text-center'>{v.Remark}</td>
                                                        <td className='text-center'>{DateFormat(v.LastUpdate,true)}</td>
                                                        <td className='text-center'><button className='btn btn-warning btn-sm text-white' onClick={()=>{_TargetDayRef.current?.Show(v.TargetDate)}}>Edit</button></td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <TargetDayCard ref={_TargetDayRef} afterupdate={()=>{_GetTargetList();}} />
        </React.Fragment>
        </>
    )
}

export default React.memo(PageTargetDayList)
