import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import { createRoot } from 'react-dom/client';

import Swal from 'sweetalert2';
import API from '../../API';

import FoodCrate from './FoodCreate';
import FoodGroupCreate from './FoodGroupCreate';

import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const PageFoodList =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const _ProductTypeRef = useRef();
    const _ProductCreate = useRef();
    const _ProductTypeCreate = useRef();
    const [_GroupList,setGroupList] = useState([]);
    const [_PageMode,setPageMode] = useState(1);
    useEffect(() => {
        (async () => {
            await _LoadGroup();
        })();

        return ()=>{ }
    }, []);
    const _LoadGroup = async()=>{
        API({method : "get",url:"/Search/Menu/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    
    
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Food/Menu/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.FoodMenuGroupID = getValues('FoodMenuGroupID');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _ProductCreate.current?.Show(d)}>Info</button>
            <button type='button' title='ลบ' className='btn btn-danger text-white' onClick={() => _DeleteMenu(d,r)}>ลบ</button>
        </div>
    )
    const _column = [
        { data: "FoodMenuGroupName", name: "FoodMenuGroupName", autoWidth: true },
        { data: "FoodMenuName", name: "FoodMenuName", autoWidth: true },
        { data: "Description", name: "Description", autoWidth: true },
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "FoodMenuId", name: "FoodMenuID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]

    const _TypeConfigs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _TypeAjax  = {
        'url': '/Food/Menu/Group/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
        }
    };

    const _TypeButtons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _ProductTypeCreate.current?.Show(d)}>Info</button>
            <button type='button' title='ลบ' className='btn btn-danger text-white' onClick={() => _DeleteGroup(d,r)}>ลบ</button>
        </div>
    )
    const _TypeColumn = [
        { data: "FoodMenuGroupName", name: "FoodMenuGroupName", autoWidth: true },
        { data: "Description", name: "Description", autoWidth: true },
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "FoodMenuGroupId", name: "FoodMenuGroupID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_TypeButtons(cellData,rowData))
        }
    ]

    const _DeleteMenu = async(FoodMenuId,val) =>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องลบเมนูห '+val.FoodMenuName+' หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Food/Menu/"+FoodMenuId+"/"+val.FoodMenuGroupId}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                ref.current?.load();
            }).catch(res => {
                return;
            });
        });
    }
    const _DeleteGroup = async(FoodMenuGroupID,val) =>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องลบกลุ่มเมนู '+val.FoodMenuGroupName+' หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Food/Group/"+FoodMenuGroupID}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                _ProductTypeRef.current?.load();
            }).catch(res => {
                return;
            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>จัดการเมนูอาหาร</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row d-flex justify-content-end mb-4'>
                                    <div className='col-lg-3 text-end'>
                                        <div className='btn-group btn-group-sm'>
                                            <button type='button' disabled={(_PageMode === 1 ? true:false)} className={(_PageMode === 1 ?'btn btn-secondary text-white active':'btn btn-primary text-white')} style={{'width':'120px'}} onClick={()=>{setPageMode(1);}} >รายการเมนู</button>
                                            <button type='button' disabled={(_PageMode === 2 ? true:false)} className={(_PageMode === 2 ?'btn btn-secondary text-white active':'btn btn-primary text-white')} style={{'width':'120px'}} onClick={()=>{setPageMode(2);}} >กลุ่มเมนู</button>
                                        </div>
                                    </div>
                                </div>
                                {(_PageMode === 1 ?
                                <>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-4 col-lg-3 mt-2 text-end'>
                                            <div className="input-group mb-3">
                                                <select className='form-control text-center' {...register("FoodMenuGroupID", { required: false })} defaultValue={0}>
                                                    <option value={0} selected>ทั้งหมด</option>
                                                    {_GroupList.map((v,i)=>{
                                                        return <option key={i} value={v.FoodMenuGroupId}>{v.FoodMenuGroupName}</option>
                                                    })}
                                                </select>
                                                <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>ref.current.load()}>ค้นหา</button>
                                                <button type='button'  className='btn btn-primary text-white' onClick={()=>{_ProductCreate.current?.Show();}}>เพิ่มเมนู</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-lg-12 table-responsive">
                                            <table id="ProductListView" className='table w-100' role='grid'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center textcenter">กลุ่ม</th>
                                                        <th className="text-center textcenter">เลือกเมนูอาหาร</th>
                                                        <th className="text-center textcenter">รายละเอียด</th>
                                                        <th className="text-center textcenter">วันที่แก้ไข</th>
                                                        <th className="text-center no-sort"></th>
                                                    </tr>
                                                </thead>
                                                <DataTable TableID={"ProductListView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                            </table>
                                        </div>
                                    </div>
                                </>:"")}
                                {(_PageMode === 2 ?
                                <>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-4 col-lg-3 mt-2 text-end'>
                                            <button type='button'  className='btn btn-primary text-white' onClick={()=>{_ProductTypeCreate.current?.Show();}}>เพิ่มกลุ่มเมนู</button>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-lg-12 table-responsive">
                                            <table id="FoodMenuGroupListView" className='table w-100' role='grid'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center textcenter">ชื่อกลุ่ม</th>
                                                        <th className="text-center textcenter">รายละเอียด</th>
                                                        <th className="text-center textcenter">วันที่แก้ไข</th>
                                                        <th className="text-center no-sort"></th>
                                                    </tr>
                                                </thead>
                                                <DataTable TableID={"FoodMenuGroupListView"} Column={_TypeColumn} Configs={_TypeConfigs} ref={_ProductTypeRef} ajax={_TypeAjax} ColumnDefs={_ColumnDefs} />
                                            </table>
                                        </div>
                                    </div>
                                </>:"")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <FoodCrate ref={_ProductCreate} update={()=>{ref.current?.load();}} />
        <FoodGroupCreate ref={_ProductTypeCreate} update={()=>{_ProductTypeRef.current?.load();}}/>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageFoodList)
