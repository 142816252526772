import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    PageNotFound,
    PageLogin,
    PageUserOnline,
    PagePackageList,
    PageQuotationList,
    PageCustomerList,
    PageProductList,
    PageFoodList,
    PageBankAccountList,
    PageQuotationRemark,
    PagePromotionList,
    PageCalendar,
    PageOrderCard} from './Pages';
import {PageCallCenterInfo,PageCallBackList,PageCallCenterWating} from './Pages/CallCenter';
import { PageChatList } from './Pages/Chat';
import {CustomerPopup} from './Pages/Popup';
import {IsLogged,GetRole} from './Utils/Function';
import {
    PageTargetDayList,
    PageTargetMonthList
} from './Pages/Setting'
const Router =() =>{
    if(IsLogged()){
        return (
            <Routes>
                <Route path="/" element={<PageCalendar />} />

                <Route path="/Home" element={<PageQuotationList />} />
                <Route path="/Quotation" element={<PageQuotationList/>}/>
                <Route path="/Customer" element={<PageCustomerList/>}/>

                <Route path="/CallCenter" element={<PageCallCenterWating/>}/>
                <Route path="/CallCenter/*" element={<PageCallCenterInfo/>}/>

                <Route path="/CallBack" element={<PageCallBackList />}/>
                <Route path="/Chat" element={<PageChatList />}/>
                <Route path="/Order" element={<PageOrderCard />}/>
                <Route path="/Calendar/*" element={<PageCalendar/>}/>
                <Route path="/Popup/Customer/*" element={<CustomerPopup/>}/>
                
                {(GetRole() === 99 ? <Route path="/User/Online" element={<PageUserOnline />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Package" element={<PagePackageList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Product" element={<PageProductList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Food" element={<PageFoodList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/BankAccount" element={<PageBankAccountList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/QuotationRemark" element={<PageQuotationRemark />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Promotion" element={<PagePromotionList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Setting/Target/Day" element={<PageTargetDayList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Setting/Target/Month" element={<PageTargetMonthList />} /> :"")}

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        )
    }else{
        localStorage.setItem('redirect',window.location.href);
        return (
            <Routes>
                <Route path="*" element={<PageLogin />} />
            </Routes>
        )
    }
    
}

export default React.memo(Router)