import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';

import { createRoot } from 'react-dom/client';
import{CustomerInfoCard} from '../../Components/Customer';
import Swal from 'sweetalert2';
import API from '../../API';
import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const PageCallBackList =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const _CustomerInfoCard = useRef();

    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 25,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/CallCenter/CallBack',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.Status = getValues('Status');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            {(r.CustoemrId != null ?<button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _CustomerInfoCard.current?.Show(r.CustoemrId)}>Info</button> : "")}
            {(r.ReadDate === null ?<button type='button' title='ติดต่อกลับ' className='btn btn-warning text-white' onClick={() =>{_OpenContactBox(r);}}>ติดต่อ</button> : "")}
        </div>
    )
    const _column = [
        {data: "CreateDate", name: "CreateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        { data: "Caller", name: "Caller", autoWidth: true },
        { data: "Extension", name: "Extension", autoWidth: true },
        { data: "CustomerCode", name: "CustomerCode", autoWidth: true },
        { data: "Status", name: "Status", autoWidth: true,render(data){
            switch(data){
                case 1 : return 'รอ';
                case 2 : return 'ติดต่อ';
                default : return '-';
            }
        }},
        { data: "UserID", name: "UserID", autoWidth: true },
        { data: "ReadDate", name: "ReadDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "CallBackId", name: "CallBackID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }
    const _OpenContactBox = async(CallBack)=>{
        console.log(CallBack);
        API({method : "put",url:"/CallCenter/CallBack/"+CallBack.CallBackId}).then(res=>{
            if (res.data.Status) {
                ref.current?.load();
                window.open("/CallCenter/"+res.data.ContactLog.ContactLogId,"_blank");
            }
        }).catch(res => {

        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>ลูกค้ารอติดต่อกลับ</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                            <div className='row d-flex justify-content-end'>
                                <div className='col-2 col-lg-2 mt-2 text-end'>
                                    <select className='form-control text-center' {...register("Status", { required: false })} defaultValue={0}>
                                        <option value={0} selected>ทั้งหมด</option>
                                    </select>
                                </div>
                                <div className='col-3 col-lg-3 text-end'>
                                    <div className='btn btn-group'>
                                        <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>ref.current.load()}>ค้นหา</button>
                                    </div>
                                </div>
                            </div>
                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="CallBackTableView" className='table w-100' role='grid'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center textcenter">วันเวลาที่ติดต่อ</th>
                                                    <th className="text-center textcenter">เบอร์โทร</th>
                                                    <th className="text-center textcenter">หมายเลขภายใน</th>
                                                    <th className="text-center textcenter">รหัสลูกค้า</th>
                                                    <th className="text-center textcenter">สถานะ</th>
                                                    <th className="text-center textcenter">เจ้าหน้าที่</th>
                                                    <th className="text-center textcenter">วันเวลาที่ติดต่อกลับ</th>
                                                    <th className="text-center no-sort"></th>
                                                </tr>
                                            </thead>
                                            <DataTable TableID={"CallBackTableView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomerInfoCard ref={_CustomerInfoCard} />
        </React.Fragment>
        </>
    )
}

export default React.memo(PageCallBackList)
