import React,{useImperativeHandle,forwardRef,useEffect, useState } from 'react';
import {Modal} from "reactstrap";

import Swal from 'sweetalert2';
import API from '../../API';
import {DateFormatJSON} from '../../Utils/Function';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TargetDay = forwardRef((props,ref) =>{
    const [ModalState,setModalState] = useState(false);
    const [_TargetDate,setTargetDate] = useState(new Date());
    const [_AmountMonth,setAmountMonth] = useState(0);
    const [_AmountNext,setAmountNext] = useState(0);
    const [_AmountOther,setAmountOther] = useState(0);
    const [_Remark,setRemark] = useState('');


    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    useImperativeHandle(ref, () => ({
        Create(){
            setTargetDate(new Date());
            setAmountMonth(0);
            setAmountNext(0);
            setAmountOther(0);
            setRemark('');
            setModalState(true);
        },
        Show(TargetDate){

            API({method : 'get',url:'/Setting/Target/Day?TargetDate='+DateFormatJSON(TargetDate,false),headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setTargetDate(res.data.Data.TargetDate);
                setAmountMonth(res.data.Data.AmountMonth);
                setAmountNext(res.data.Data.AmountNext);
                setAmountOther(res.data.Data.AmountOther);
                setRemark(res.data.Data.Remark);

                setModalState(true);
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        }
    }));

    const _Save = async()=>{

        if(_TargetDate === undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบวันที่เป้า'
            });
            return;
        }

        if(isNaN(_AmountMonth) || _AmountMonth === 0 || isNaN(_AmountNext) || _AmountNext === 0 || isNaN(_AmountOther) || _AmountOther ===0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบยอด'
            });
            return;
        }
        const _reqObj = {
            TargetDate:DateFormatJSON(_TargetDate,false),
            AmountMonth:_AmountMonth,
            AmountNext:_AmountNext,
            AmountOther:_AmountOther,
            Remark:_Remark
        }
        
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Setting/Target/Day/Save',data:_reqObj,headers: { "Content-Type": "multipart/form-data" }}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                if(props.afterupdate != undefined){
                    try{
                        props.afterupdate();
                    }catch(e){
                        console.log(e);
                    }
                }
                setModalState(false);
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });
    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xs'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">เป้าประจำวัน</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>

            <div className='modal-body'>
                <div className='row mb-3'>
                    <div className='col-lg-4 text-end fw-bold'>วันที่</div>
                    <div className='col-lg-8'>
                        <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={_TargetDate} onChange={(date)=>{setTargetDate(date)}} selectsStart startDate={_TargetDate}/>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4 text-end fw-bold'>ยอดประจำเดือน</div>
                    <div className='col-lg-4'>
                        <input type="text" className="form-control  text-end" placeholder="ยอดประจำเดือน" autoComplete='off' value={_AmountMonth} onChange={(e)=>{setAmountMonth(e.target.value);}}  />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4  text-end fw-bold'>ยอดเดือนถัดไป</div>
                    <div className='col-lg-4'>
                        <input type="text" className="form-control  text-end" placeholder="ยอดเดือนถัดไป" autoComplete='off' value={_AmountNext} onChange={(e)=>{setAmountNext(e.target.value);}} />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4  text-end fw-bold'>ยอดอื่นๆ</div>
                    <div className='col-lg-4'>
                        <input type="text" className="form-control text-end" placeholder="ยอดอื่นๆ" autoComplete='off' value={_AmountOther} onChange={(e)=>{setAmountOther(e.target.value);}} />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-lg-4  text-end fw-bold'>หมายเหตุ</div>
                    <div className='col-lg-8'>
                        <textarea className="form-control" placeholder="หมายเหตุ" value={_Remark} defaultValue={_Remark} onChange={(e)=>{setRemark(e.target.value);}}></textarea>
                    </div>
                </div>
                
                <div className='row mt-4'>
                    <div className='col-lg-12 text-center'>
                        <button type="button" className='btn btn-success btn-lg' onClick={()=>{_Save();}}>บันทึก</button>
                    </div>
                </div>
            </div>
        </Modal>
        </>)
        
});

export default React.memo(TargetDay)