import React, { createRef, forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import { Table, Modal } from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import { SearchSubDistrict, SearchCustomer } from '../../Utils/Search';
import { DateThaiFormat, DateFormat, DateThai, DateFormatJSON, MobileFormat, NumberFormat, RootURL } from '../../Utils/Function';
import parse from 'html-react-parser'
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import "react-datepicker/dist/react-datepicker.css";
import CustomerList from './CustomerList';
import { PopoverBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const CustomerInfoDetail = forwardRef((props, ref) => {
    const { register, handleSubmit, watch, reset, getValues, setValue, control, formState: { errors } } = useForm();
    const [Detail, setDetail] = useState(null);
    const [ViewMode, setViewMode] = useState(1);
    const [Items, setItems] = useState([]);
    const [_ContactNoteList, setContactNoteList] = useState([]);
    const [BrithDay, setBrithDay] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const datepickerRef = useRef(null);
    const customerListRef = useRef();
    const [CustomerTypeList, setCustomerTypeList] = useState([]);
    const [CustomerListGroupID, setCustomerListGroupID] = useState([]);
    const [TaxInvoice, setTaxInvoice] = useState(null);
    const Controll = [
        { label: 'ไม่ระบุ', value: 0 },
        { label: 'บริษัท', value: 1 },
        { label: 'ร้าน', value: 2 },
    ]
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setContactNoteList([]);
            _Load();
            setItems(1);
            //console.log('detail');

        })();

        return () => { }
    }, []);

    useImperativeHandle(ref, () => ({

    }));
    const _Load = async () => {
        if (props.CustomerID === undefined) return;
        Swal.showLoading();

        API({ method: "get", url: "/Customer/" + props.CustomerID }).then(res => {
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                if (props.error != undefined) {
                    try {
                        props.error();
                    } catch (e) {
                        console.log(e);
                    }
                }
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                if (props.error != undefined) {
                    try {
                        props.error();
                    } catch (e) {
                        console.log(e);
                    }
                }
                return;
            }
            Swal.close();
            setDetail(res.data.Data);
            setTaxInvoice(res.data.Data.TaxInvoice);
            _GetCustomerGroupList();
            if (props.modal != undefined) {
                try {
                    props.modal(true, res.data.Data);
                } catch (e) {
                    console.log(e);
                }
            }
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message + ' [' + res.code + ']',
            });
            if (props.error != undefined) {
                try {
                    props.error();
                } catch (e) {
                    console.log(e);
                }
            }
        });
    }
    const _LoadContactNote = async () => {
        if (props.CustomerID === undefined) return;
        Swal.showLoading();
        API({ method: "get", url: "/Customer/" + props.CustomerID + "/Note?Limit=100" }).then(res => {
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                if (props.error != undefined) {
                    try {
                        props.error();
                    } catch (e) {
                        console.log(e);
                    }
                }
                return;
            }
            setContactNoteList(res.data.Data);
            setViewMode(3);
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message + ' [' + res.code + ']',
            });
        });
    }

    const _GetCustomerGroupList = () => {
        setCustomerListGroupID([]);
        API({ method: "get", url: "/Search/Customer/Group" }).then(res => {
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setCustomerListGroupID(res.data.Data);
        }).catch(res => {
            return;
        });
    }
  
    const _Edit = async () => {
        if (props.CustomerID === undefined) return;
       
        let _reqObj = {
            Name: Detail.Name,
            NickName: Detail.NickName,
            Mobile: Detail.Mobile,
            BrithDay : (BrithDay != null && !isNaN(BrithDay)?DateFormatJSON(BrithDay,false):null),
            AccountType: Detail.AccountType,
            CustomerGroupId: Detail.CustomerGroupId,
            CustomerType: Detail.CustomerType,
            Address: Detail.Address,
            SubdistrictId : getValues('SubdistrictId') === undefined || getValues('SubdistrictId') == null ? 0 : getValues('SubdistrictId'),
            ZipCode: Detail.ZipCode,
            Note: Detail.Note,
            TaxName: Detail.TaxName,
            TaxID: Detail.TaxID,
            TaxBranchName: Detail.TaxBranchName,
            TaxAddress: Detail.TaxAddress,
            TaxZipCode: Detail.TaxZipCode,
            TaxPhone: Detail.TaxPhone,
            TaxInvoice: TaxInvoice


        }
        console.log(_reqObj);

        API({ method: "post", url: "/Customer/" + props.CustomerID + "/Edit", data: _reqObj }).then(res => {
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                if (props.error != undefined) {
                    try {
                        props.error();
                    } catch (e) {
                        console.log(e);
                    }
                }
                return;
            }

            if (props.update != undefined) {
                try {
                    props.update(res.data.Data);
                } catch (e) {
                    console.log(e);
                }
            }
            Swal.fire({
                icon: 'success',
                title: res.data.Message,
            }).then(() => {
                navigate(0); // Force reload by navigating to the same page
            });

            setBrithDay(null);
            setShowSelect(false);
            setShowDatePicker(false);
            reset();
          
          
        }).catch(res => {
            if (res.response.data != undefined) {
                Swal.fire({
                    icon: 'error',
                    title: res.code,
                    text: res.response.data.Message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message + ' [' + res.code + ']',
                });
            }
        });

    }

    return (
        <>
            <div className='row'>
                <div className='col-lg-10'>
                    <div className='btn-group btn-lg'>
                        <button type="button" className={(ViewMode === 1 ? 'btn btn-info text-white active' : 'btn btn-secondary text-white')} onClick={() => { setViewMode(1) }}>ข้อมูลลูกค้า</button>
                        <button type="button" disabled={(Detail != null && Detail.AccountType === 1 ? false : true)} className={(ViewMode === 2 ? 'btn btn-info text-white active' : 'btn btn-secondary text-white')} onClick={() => { setViewMode(2) }}>ข้อมูลใบกำกับภาษี</button>
                        <button type="button" className={(ViewMode === 3 ? 'btn btn-info text-white active' : 'btn btn-secondary text-white')} onClick={() => { _LoadContactNote() }}>ประวัติการติดต่อ</button>

                    </div>
                </div>
                <div className='col-lg-2 fs-5 text-end'></div>
            </div>

            {(ViewMode === 1 ? <>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label">รหัสลูกค้า</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null ? Detail.CustomerCode : "")} />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">ชื่อลูกค้า</label>
                                        <input type='text' className="form-control" autoComplete='off' defaultValue={(Detail != null ? Detail.Name : "")}
                                            onChange={(e) => {
                                                setDetail(Detail => ({ ...Detail, Name: e.target.value }));
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">เบอร์โทร</label>
                                        <input type='number' className="form-control" defaultValue={(Detail != null ? Detail.Mobile : "")}
                                            onChange={(e) => {
                                                setDetail(Detail => ({ ...Detail, Mobile: e.target.value }));
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">ชื่อเล่น</label>
                                        <input type='text' className="form-control" defaultValue={(Detail != null ? Detail.NickName : "")}
                                            onChange={(e) => {
                                                setDetail(Detail => ({ ...Detail, NickName: e.target.value }));
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <div>
                                            <label className="form-label">วันเกิดลูกค้า</label>
                                            {!showDatePicker && (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={Detail?.BrithDay ? DateFormat(Detail.BrithDay, false) : ""}

                                                    onClick={() => setShowDatePicker(true)}
                                                />
                                            )}

                                            {showDatePicker && (
                               

                                                <DatePicker className='form-control text-center' 
                                                dateFormat={'dd/MM/yyyy'} 
                                                selected={BrithDay} 
                                                onChange={(date) => { setBrithDay(date) }} 
                                                selectsStart startDate={BrithDay}
                                                showYearDropdown
                                                showMonthDropdown
                                                dropdownMode="select"
                                                scrollableYearDropdown
                                                 />

                                            )}


                                        </div>

                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label">ประเภท</label>
                                        <Controller
                                            name="AccountType"
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    className="form-control"
                                                    {...field}
                                                    value={Detail?.AccountType ?? field.value ?? "0"}
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        setDetail(prev => ({ ...prev, AccountType: selectedValue }));
                                                        field.onChange(selectedValue);
                                                    }}
                                                >
                                                    {Controll.map((v, i) => (
                                                        <option key={i} value={v.value}>{v.label}</option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">กลุ่มลูกค้า</label>
                                        <Controller
                                            name="CustomerGroupId"
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    className="form-control"
                                                    {...field}
                                                    value={Detail?.CustomerGroupId ?? field.value ?? "0"}
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        setDetail(prev => ({ ...prev, CustomerGroupId: selectedValue }));
                                                        field.onChange(selectedValue);
                                                    }}
                                                >

                                                    {CustomerListGroupID.map((v, i) => (
                                                        <option key={i} value={v.CustomerGroupId}>{v.CustomerGroupName}</option>
                                                    ))}
                                                </select>
                                            )}
                                        />

                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">ประเภทลูกค้า</label>
                                        <input type='text' className="form-control" defaultValue={(Detail != null && Detail.CustomerType != null ? Detail.CustomerType.CustomerTypeName : "ไม่ระบุ")}
                                            onChange={(e) => {
                                                setDetail(Detail => ({ ...Detail, CustomerType: e.target.value }));
                                            }} placeholder='ไม่ระบุ'
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-5">
                                        <label className="form-label">ที่อยู่</label>
                                        <textarea className="form-control" defaultValue={(Detail != null ? Detail.Address : "")}
                                            onChange={(e) => {
                                                setDetail(Detail => ({ ...Detail, Address: e.target.value }));
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <label className="form-label">ตำบล / อำเภอ / จังหวัด</label>
                                        <div>
                                            {!showSelect && (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={Detail?.Subdistrict?.FullName || ""}
                                                    readOnly
                                                    onClick={() => setShowSelect(true)}
                                                />
                                            )}
                                            {showSelect && (
                                                <Controller
                                                    name="SubdistrictId"
                                                    control={control}
                                                    rules={{ required: false }}
                                                    render={({ field }) => (
                                                        <AsyncSelect
                                                            name={field.name}
                                                            cacheOptions
                                                            defaultOptions
                                                            placeholder="ค้นหา ตำบล / อำเภอ / จังหวัด"
                                                            loadOptions={SearchSubDistrict}
                                                            onChange={(selected) => {
                                                                field.onChange(selected.SubdistrictId);
                                                                setShowSelect(false);
                                                                setDetail(prev => ({ ...prev, Subdistrict: selected }));
                                                            }}
                                                            getOptionLabel={(e) => e.FullName}
                                                            getOptionValue={(e) => e.SubdistrictId}
                                                            autoFocus
                                                            onBlur={() => setShowSelect(false)}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">รหัสไปรษณีย์</label>
                                        <input type='text' className="form-control text-center" defaultValue={(Detail != null ? Detail.ZipCode : "")}
                                            onChange={(e) => {
                                                setDetail(Detail => ({ ...Detail, ZipCode: e.target.value }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-12">
                                        <label className="form-label">บันทึกหมายเหตุ</label>
                                        <textarea className="form-control" defaultValue={(Detail != null ? Detail.Note : "")}
                                            onChange={(e) => {
                                                setDetail(Detail => ({ ...Detail, Note: e.target.value }));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : "")}
            {
                (ViewMode === 2 ? <>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row mt-3'>
                                        <div className="col-md-6">
                                            <label className="form-label">ชื่อธุรกิจ</label>
                                            <input type='text' className="form-control" defaultValue={(Detail != null ? Detail.TaxName : "")}
                                                onChange={(e) => {
                                                    setDetail(Detail => ({ ...Detail, TaxName: e.target.value }));
                                                }}

                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">เลขประจำตัวผู้เสียภาษี</label>
                                            <input type='text' className="form-control" defaultValue={(Detail != null ? Detail.TaxID : "")}
                                                onChange={(e) => {
                                                    setDetail(Detail => ({ ...Detail, TaxID: e.target.value }));
                                                }}

                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label">สำนักงาน/สาขา</label>
                                            <input type='text' className="form-control" defaultValue={(Detail != null ? Detail.TaxBranchName : "")}
                                                onChange={(e) => {
                                                     setDetail(Detail => ({ ...Detail, TaxBranchName: e.target.value }));
                                                }}
                                            
                                            />
                                        </div>

                                    </div>
                                    <div className='row mt-3'>
                                        <div className="col-md-5">
                                            <label className="form-label">ที่อยู่จัดงาน</label>
                                            <textarea className="form-control" defaultValue={(Detail != null ? Detail.TaxAddress : "")}
                                                onChange={(e) => {
                                                    setDetail(Detail => ({ ...Detail, TaxAddress: e.target.value }));
                                                }}
                                            />

                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">รหัสไปรษณีย์</label>
                                            <input type='text' className="form-control text-center" defaultValue={(Detail != null ? Detail.TaxZipCode : "")}
                                                onChange={(e) => {
                                                    setDetail(Detail => ({ ...Detail, TaxZipCode: e.target.value }));
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">เบอร์โทรศัพท์</label>
                                            <input type='text' className="form-control text-center" defaultValue={(Detail != null ? Detail.TaxPhone : "")}
                                                onChange={(e) => {
                                                    setDetail(Detail => ({ ...Detail, TaxPhone: e.target.value }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </> : "")
            }

            {
                (ViewMode === 3 ? <>
                    <div className='row'>
                        <div className='col-lg-12'>
                            {_ContactNoteList.map((v, i) => (
                                <div className='row mt-4 mb-4'>
                                    <div className='col-lg-12'>
                                        <div className='card'>
                                            <div className='card-header d-flex justify-content-between align-items-center '>
                                                <h6>{DateThaiFormat(v.CreateDate, true)}</h6>
                                                <div className='btn btn-group btn-group-sm'></div>
                                            </div>
                                            <div className='card-body'>{v.Note}</div>
                                            <div className='card-footer'>
                                                <div className='row'>
                                                    <div class="col-6 text-start">
                                                        <span style={{ fontSize: '14px' }}>{v.ChannelName}</span>
                                                    </div>
                                                    <div className='col-6 text-end'>
                                                        <span style={{ fontSize: '14px' }}><b>บันทึกโดย</b> :: {v.CreateByName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </> : "")
            }
            {
                ViewMode === 1
                    ? (
                        <div className='row mt-4'>
                            <div className='col-md-12 mt-4 text-end'>
                                <div className='btn-group btn-group-lg'>
                                    <button type="submit" className='btn btn-success' onClick={() => { _Edit();  }}> บันทึก </button>
                                </div>
                            </div>
                        </div>
                    )
                    : null
            }
        </>
    );

});

export default React.memo(CustomerInfoDetail)