import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, TimeFormat } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';

const FoodCreate = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();
    const [_FoodMenuGroupID,setFoodMenuGroupID] = useState(0);
    const [_FoodMenuGroupList,setFoodMenuGroupList] = useState([]);
    const [ModalState,setModalState] = useState(false);
    const [_FoodMenuList,setFoodMenuList] = useState([]);
    const [_FoodGroupList,setFoodGroupList] = useState([]);
    const [_Detail,setDetail] = useState(null);

    useEffect(() => {
        (async () => {
            setFoodMenuGroupList([]);
            setFoodMenuGroupID(0);
            setFoodMenuList([]);
        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(ProductID){
            _ClearValuAction();
            _GetPackageGroupList();
            setFoodMenuList([]);
            _Initial();
            if(ProductID === undefined){
                setModalState(true);
            }else{
                _LoadDetail(ProductID);
            }        }
      }));
    const _Initial = ()=>{
        
    }
    const _LoadDetail = async (FoodMenuID)=>{
        API({method : "get",url:"/Food/Menu/"+FoodMenuID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setDetail(res.data.Data);
            setValue('FoodMenuID',res.data.Data.FoodMenuID);
            setValue('FoodMenuName',res.data.Data.FoodMenuName);
            setValue('Description',res.data.Data.Description);
            setFoodMenuList(res.data.Data.FoodMenuGroupID);
            setModalState(true);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: res.data.Message,
            });
            return;
        });
    }
    const _GetPackageGroupList = async()=>{
        setFoodGroupList([]);
        API({method : "get",url:"/Search/Menu/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setFoodGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    const _Save = async()=>{
        if(getValues('FoodMenuName') == undefined || getValues('FoodMenuName') === ""){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ ชื่ออาหาร'
            });
            return;
        }

        if(_FoodMenuList.length === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดเลือก กลุ่มอาหาร'
            });
            return;
        }
        /**
        let _FoodMenuAR = [];
        _FoodMenuList.find((v,i)=>{
            _FoodMenuAR.push(v.FoodMenuGroupId);
        });
        **/
        let _reqObj = {
            FoodMenuID:(_Detail != null ? _Detail.FoodMenuId:null),
            FoodMenuName: getValues('FoodMenuName'),
            Description: getValues('Description'),
            FoodMenuGroupID: _FoodMenuList
        }

        console.log(_reqObj);
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Food/Menu/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                _ClearValuAction();
                if(props.update != undefined){
                    try{
                        props.update(res.data.Data);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }

    const _ClearValuAction = ()=>{
        reset();
        setFoodMenuGroupList([]);
    }
    const _ClearValue = ()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }

    const _AddMenuITem = async ()=>{
        if(_FoodMenuGroupID === 0) return;
        let _AR = [... _FoodMenuList];
        let _Error=false;
        _AR.find((v,i)=>{
            if(v.FoodMenuGroupId === _FoodMenuGroupID) _Error = true;
        });
        if(_Error) return;
        _FoodGroupList.find((v,i)=>{
            if(v.FoodMenuGroupId ===_FoodMenuGroupID){
                _AR.push(v);

            }
        })
        setFoodMenuList(_AR);
        
    }
    const _DeleteMenuItem = (FoodMenuGroupID)=>{
        let _AR = [... _FoodMenuList];
        let _NAR = [];
        _AR.find((v,i)=>{
            if(v.FoodMenuGroupId != FoodMenuGroupID) _NAR.push(v);
        });
        setFoodMenuList(_NAR);
    }
      return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">{(_Detail != null ? "แก้ไขรายการอาหาร":"สร้างรายการอาหาร")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'300px'}}>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className='row mt-4'>
                        <div className="col-md-8">
                            <label className="form-label">ชื่อเมนูอาหาร</label>
                                <input type='text' className="form-control" placeholder="ชื่อเมนูอาหาร" autoComplete="off"   {...register("FoodMenuName", { required: false })}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-4">
                            <label className="form-label">กลุ่มเมนูอาหาร</label>
                            <div class="input-group mb-3">
                                <select className='form-control text-left' onChange={(e)=>{setFoodMenuGroupID(e.target.value);}}>
                                    <option value={0}>เลือกกลุ่มเมนูอาหาร</option>
                                    {_FoodGroupList.map((v,i)=>(
                                     <option value={v.FoodMenuGroupId}>{v.FoodMenuGroupName}</option>   
                                ))}
                                </select>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-success" disabled={(_FoodMenuGroupID === 0 ? true:false)} type="button" onClick={()=>{_AddMenuITem();}}>เพิ่ม</button>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-6">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>กลุ่มเมนูอาหาร</th>
                                        <th className='text-center'>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(_FoodMenuList.map((v,i)=>(
                                        <tr key={i}>
                                            <td className='text-left'>{v.FoodMenuGroupName}</td>
                                            <td className='text-end'>
                                                <button type='button' className='btn btn-danger btn-sm' onClick={()=>{_DeleteMenuItem(v.FoodMenuGroupId)}}>ลบ</button>
                                            </td>
                                        </tr>
                                    )))}
                                    
                                </tbody>
                            </table>
                        </div>
                       
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">รายละเอียด</label>
                            <Controller name="Description" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    
                    <div className='row mt-3'>
                        <div className='col-md-12 mt-4 text-end'>
                            <div className='btn-group btn-group-lg'>
                                <button type="button" className='btn btn-danger' disabled={(_Detail != null ? true:false)} onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                <button type="submit" className='btn btn-success'>บันทึก</button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
        </>
      )
});
export default React.memo(FoodCreate);