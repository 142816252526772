import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile } from '../../Utils/Function';
import {GetNameOfUser} from '../../Utils/User';
import {OrderStatus,OrderStatusLabel} from '../../Utils/Status';
import OrderCardStatusInfo from './OrderCardStatusInfo';
import parse from 'html-react-parser'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderCardNew = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const _OrderCardStatusInfo = useRef();
    const [ModalState,setModalState] = useState(false);
    const [QuotationDate,setQuotationDate] = useState(moment().toDate());
    const [EventDate,setEventDate] = useState(moment().add(3, 'day').toDate());

    const [PackageGroupID,setPackageGroupID] = useState(0);
    const [PackageGroupList,setPackageGroupList] = useState([]);
    const [PackageList,setPackageList] = useState([]);
    const[PackageID,setPackageID] = useState(0);
    const[PackageSave,setPackageSave] = useState([]);

    const[PromotionList,setPromotionList]=useState([]);
    const[PromotionSave,setPromotionSave]=useState([]);
    const[PromotionID,setPromotionID] = useState(0);

    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(){
            _GetPackageGroupList();
            _GetPromotionList();
            setModalState(true);
        }
      }));

    const _GetPackageGroupList = ()=>{
        setPackageGroupList([]);
        API({method : "get",url:"/Package/Group/List"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPackageGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetPackageList = (GroupID)=>{
        setPackageList([]);
        API({method : "get",url:"/Package/Group/"+GroupID+"/List"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPackageList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetPromotionList = ()=>{
        setPromotionList([]);
        API({method : "get",url:"/Promotion/List"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPromotionList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _AccountType = (AccountType)=>{
        switch(AccountType){
            case 0:return '-';
            case 1:return 'บริษัท';
            case 2:return 'ร้าน';
            default:return '';
        }
    }
    const _Reason = (ReasonID) =>{
        if(ReasonID === 0) return 'ไม่ระบุ';
    }
    const _CloseModel = ()=>{
        setModalState(false);
        if(props.callback != undefined){
            try{
                props.callback();
            }catch(e){}
        }
    }
    const _UpdateOrderCardStatusInfo = (val)=>{
        //if(val) setModalState(false); else setModalState(true);
        //if(!val) _GetOrderDetail(OrderDetail.OrderId);
    }
    const _Save = async()=>{
        if(getValues('CustomerName') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุชื่อลูกค้า'
            });
            return;
        }
        if(getValues('CustomerMobile') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบเบอร์โทร'
            });
            return;
        }
        if(isNaN(Number(getValues('PeopleNum')))){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบจำนวนหัว'
            });
            return;
        }
        if(isNaN(Number(getValues('QuotationAmount')))){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบยอดเสนอ'
            });
            return;
        }

        const _PackageSave = [... PackageSave];
        let _PackageObj = [];
        _PackageSave.forEach((e)=>{
            _PackageObj.push(e.Package.PackageId);
        });
        if(_PackageObj.length == 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบแพ็คเก็จ'
            });
            return;
        }
        const _PromotionSave = [... PromotionSave];
        let _PromotionObj = [];
        _PromotionSave.forEach((e)=>{
            _PromotionObj.push(e.PromotionId);
        });
        let _reqObj = {
            CustomerName : getValues('CustomerName'),
            CustomerMobile : CleanMobile(getValues('CustomerMobile')),
            QuotationDate:DateFormatJSON(QuotationDate),
            EventDate:DateFormatJSON(EventDate),
            AccountType : (isNaN(Number(getValues('AccountType')))?1:Number(getValues('AccountType'))),
            PeopleNum:Number(getValues('PeopleNum')),
            QuotationAmount : Number(getValues('QuotationAmount')),
            LineChatURL:(getValues('LineChatURL') == undefined ? null : getValues('LineChatURL')),
            CalendarURL:(getValues('CalendarURL') == undefined ? null : getValues('CalendarURL')),
            Remark:(getValues('Remark') == undefined ? null : getValues('Remark')),
            Packages : _PackageObj,
            Promotions:_PromotionObj
        };

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Order/Create',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                /**
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message
                });
                **/
                setModalState(false);
                _ClearValuAction();
                if(props.aftercreate != undefined){
                    try{
                        props.aftercreate(res.data.Data.OrderId);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }
    const _AddPackage = ()=>{
        const _PackageList = PackageList;
        const _PackageGroupList = PackageGroupList;
        const _PackageDetail = _PackageList.find((e) => e.PackageId==PackageID);
        const _PackageGroup = _PackageGroupList.find((e) => e.PackageGroupId==_PackageDetail.PackageGroup);

        let _Package = [...PackageSave,{PackageGroup:_PackageGroup,Package :_PackageDetail}];
        setPackageSave(_Package);
        setPackageID(0);
        _GetPackageList(PackageGroupID);
    }
    const _DeletePackate = (Index) =>{
        const _Package = [...PackageSave];
        let _PackageN = [];
        _Package.find((e,i) => {
            if(Index != i){
                _PackageN.push(e);
            }
        });
        setPackageSave(_PackageN);

    }

    const _AddPromotion = ()=>{
        if(PromotionID == 0) return;
        const _PromotionList = PromotionList;
        const _PromotionDetail = _PromotionList.find((e) => e.PromotionId==PromotionID);
        if(_PromotionDetail == undefined) return;

        let _Promotion = [...PromotionSave,_PromotionDetail];
        setPromotionSave(_Promotion);
        setPromotionID(0);
        _GetPromotionList();
    }
    const _DeletePromotion = (Index) =>{
        const _Promotion = [...PromotionSave];
        let _PromotionN = [];
        _Promotion.find((e,i) => {
            if(Index != i){
                _PromotionN.push(e);
            }
        });
        setPromotionSave(_PromotionN);
    }
    const _ClearValue=()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }
    const _ClearValuAction=()=>{
        reset();
        setEventDate(moment().add(3, 'day').toDate());

        setPackageGroupID(0);
        setPackageID(0);
        setPackageList([]);
        setPackageSave([]);

        setPromotionID(0);
        setPromotionSave([]);
        setValue('CustomerName',null);
        setValue('AccountType',1);
        setValue('LineChatURL','');
        setValue('CalendarURL','');

        setValue('Remark','');
    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">บันทึกรายใบเสนอราคา</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{_CloseModel()}}></button>
            </div>

            <div className='modal-body'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                                <div className="col-md-4">
                                    <label className="form-label">ชื่อลูกค้า</label>
                                    <input type='text' className="form-control" placeholder="ชื่อลูกค้า" autoComplete="off"  {...register("CustomerName", { required: false })}/>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label">เบอร์โทร</label>
                                    <input type='text' className="form-control" placeholder="เบอร์โทร" autoComplete="off"   {...register("CustomerMobile", { required: false })}/>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">วันที่เสนอราคา</label>
                                    <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={QuotationDate} onChange={(date)=>{setQuotationDate(date)}} selectsStart startDate={QuotationDate}/>
                                </div>

                                <div className="col-md-2">
                                    <label className="form-label">วันที่จัดงาน</label>
                                    <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={EventDate} onChange={(date)=>{setEventDate(date)}} selectsStart startDate={EventDate}/>
                                </div>

                                <div className="col-md-1">
                                    <label className="form-label">ประเภท</label>
                                    <Controller name="AccountType" control={control} rules={{required:false}} render={({field})=>(
                                        <select className='form-control text-center' onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                            <option value="1" selected={(field.value == 1 ? true:false)}>บริษัท</option>
                                            <option value="2" selected={(field.value == 2 ? true:false)}>ร้าน</option>
                                        </select>
                                    )} />
                                    {errors.AccountType && errors.Remark.type == 'required' && <small className="form-text alert alert-danger">โปรดระบุประเภท</small> }
                                </div>

                                <div className='col-md-3'>
                                    <label className="form-label">ผลิตภัณฑ์</label>
                                    <select className='form-control' defaultValue={PackageGroupID} onChange={(e)=>{setPackageGroupID(e.target.value);_GetPackageList(e.target.value);}}>
                                        <option value={0} selected={(PackageGroupID == 0 ? true : false)}>เลือก</option>
                                        {PackageGroupList.map((v,i)=>{
                                            return <option key={i} value={v.PackageGroupId} selected={(PackageGroupID == v.PackageGroupId? true : false)}>{v.PackageGroupName}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='col-md-8'>
                                    <label className="form-label">แพ็คเก็จ :</label>
                                    <select className='form-control' defaultValue={PackageID} onChange={(e)=>{setPackageID(e.target.value);}}>
                                        <option value={0} selected={(PackageID == 0 ? true : false)}>เลือก</option>
                                        {PackageList.map((v,i)=>{
                                            return <option key={i} value={v.PackageId} selected={(PackageID == v.PackageId ? true : false)}>{v.Price+' | '+v.PackageName + ' | '+v.Description}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='col-md-1'>
                                    <button type="button" className='btn btn-success' style={{'marginTop':'32px'}} disabled={(PackageID == 0 ? true : false)} onClick={()=>{_AddPackage();}}>เพิ่ม</button>
                                </div>
                                <div className='col-md-12 mt-4 mb-4'>
                                        <table className='table'>
                                            <thead>
                                                    <tr>
                                                        <th className='text-center'>ผลิตภัณฑ์</th>
                                                        <th className='text-center'>แพ็คเก็จ</th>
                                                        <th className='text-center'>รายละเอียด</th>
                                                        <th className='text-center'>ราคา</th>
                                                        <th className='text-center'></th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                            {PackageSave.map((v,i)=>(
                                                <tr key={i}>
                                                    <td className='text-center'>{v.PackageGroup.PackageGroupName}</td>
                                                    <td className='text-left'>{v.Package.PackageName}</td>
                                                    <td className='text-left'>{v.Package.Description}</td>
                                                    <td className='text-end'>{NumberFormat(v.Package.Price)}</td>
                                                    <td className='text-end'>
                                                        <button type="button" className='btn btn-danger btn-sm' onClick={()=>{_DeletePackate(i);}}>ลบ</button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">จำนวนหัว</label>
                                    <input type='text' className="form-control" placeholder="จำนวนหัว" autoComplete="off"   {...register("PeopleNum", { required: false })}/>
                                </div>

                                <div className="col-md-2">
                                    <label className="form-label">ยอดเสนอ</label>
                                    <input type='text' className="form-control" placeholder="ยอดเสนอ" autoComplete="off"   {...register("QuotationAmount", { required: false })}/>
                                </div>

                                <div className='col-md-5'>
                                    <label className="form-label">โปรโมชั่น :</label>
                                    <select className='form-control' defaultValue={PromotionID} onChange={(e)=>{setPromotionID(e.target.value);}}>
                                        <option value={0} selected>เลือก</option>
                                        {PromotionList.map((v,i)=>{
                                            return <option key={i} value={v.PromotionId}>{v.PromotionCode+' | '+v.PromotionName}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='col-md-2'>
                                    <button type="button" className='btn btn-success' style={{'marginTop':'32px'}} disabled={( PromotionID == 0 ? true : false)} onClick={()=>{_AddPromotion();}}>เพิ่ม</button>
                                </div>
                                <div className='col-md-12 mt-4 mb-4'>
                                        <table className='table'>
                                            <thead>
                                                    <tr>
                                                        <th className='text-center'>โปรโมชั่น Code</th>
                                                        <th className='text-center'>โปรโมชั่น</th>
                                                        <th className='text-center'></th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                            {PromotionSave.map((v,i)=>(
                                                <tr key={i}>
                                                    <td className='text-center'>{v.PromotionCode}</td>
                                                    <td className='text-left'>{v.PromotionName}</td>
                                                    <td className='text-end'>
                                                        <button type="button" className='btn btn-danger btn-sm' onClick={()=>{_DeletePromotion(i);}}>ลบ</button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Line แชท</label>
                                    <Controller name="LineChatURL" control={control} rules={{required:false}} render={({field})=>(
                                        <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                    )} />
                                    {errors.LineChatURL && errors.LineChatURL.type == 'required' && <small className="form-text alert alert-danger">โปรดระบุ LineChatURL</small> }
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">ปฎิทิน Google Calendar</label>
                                    <Controller name="CalendarURL" control={control} rules={{required:false}} render={({field})=>(
                                        <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                    )} />
                                    {errors.CalendarURL && errors.CalendarURL.type == 'required' && <small className="form-text alert alert-danger">โปรดระบุ CalendarURL</small> }
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">หมายเหตุ</label>
                                    <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                                        <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                                    )} />
                                    {errors.Remark && errors.Remark.type == 'required' && <small className="form-text alert alert-danger">โปรดระบุ CalendarURL</small> }
                                </div>
                                <div className='col-md-12 mt-4 text-end'>
                                    <div className='btn-group btn-group-lg'>
                                        <button type="button" className='btn btn-danger' onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                        <button type="submit" className='btn btn-success'>บันทึก</button>
                                    </div>
                                </div>
                        </Form>
                    </div>
                </div>                
            </div>
        </Modal>
        </>)
        
});

export default React.memo(OrderCardNew)