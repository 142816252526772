import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Col,Row,Form,Modal} from "reactstrap";
import { Controller, useForm } from "react-hook-form";

import Swal from 'sweetalert2';
import API from '../../API';
import { DateFormat, DateThai,DateThaiFormat,MobileFormat, NumberFormat } from '../../Utils/Function';
import {OrderStatus,OrderStatusList} from '../../Utils/Status';

import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderCardStatusInfo = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [ModalState,setModalState] = useState(false);
    const [OrderDetail,setOrderDetail] = useState(undefined);
    const [ReasonList,setReasonList] = useState([]);
    const [Appointment,setAppointment] = useState(0);
    const [AppointmentDate,setAppointmentDate] = useState(moment().add(3, 'day').toDate());
    const [AppointmentTime,setAppointmentTime] = useState(moment().add(3, 'day').format("HH:mm"));

    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    const _ModalState = (val)=>{
        if(props.callback != undefined){
            try{
                props.callback(val);
            }catch(e){}
        }
        if(val === true){
            reset();
            setValue('Remark','')
        }
        
        setModalState(val)
    }
    const _GetReasonList = async()=>{
        API({method : "get",url:"/Order/Status/Reason"}).then(res=>{
            if (res.status !== 200) {
                setReasonList([]);
            }
    
            if (!res.data.Status) {
                setReasonList([]);
            }
            setReasonList(res.data.Data);
        }).catch(res => {
            setReasonList([]);
        });
    }
    useImperativeHandle(ref, () => ({
        Show(_OrderDetail){
            setOrderDetail(_OrderDetail);
            _GetReasonList();
            setAppointment(0);
            _ModalState(true);
        }
      }));

    const _Save = async()=>{
        const _reqObj = {
            Reason: (getValues('ReasonStatus') != undefined ? getValues('ReasonStatus') : 0),
            Status : getValues('OrderStatus'),
            Remark : (getValues('Remark') != undefined ? getValues('Remark'):""),
            Appointment:Appointment,
            AppointmentDate:moment(AppointmentDate).format('DD/MM/yyyy')+' '+AppointmentTime+':00'
        }
        console.log(_reqObj);

        API({method : "post",url:"/Order/Status/"+OrderDetail.OrderId,data:_reqObj}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                return;
            }
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: res.data.Message,
            });
            _ModalState(false);
            return;
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">รายละเอียด :: {(OrderDetail != undefined ? OrderDetail.CustomerName + ' ['+MobileFormat(OrderDetail.CustomerMobile)+'] :: '+DateThai(OrderDetail.EventDate):"1")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{_ModalState(false)}}></button>
            </div>

            <div className='modal-body'>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className="col-md-4">
                        <label className="form-label">ชื่อลูกค้า</label>
                        <input type="text" className="form-control" defaultValue={(OrderDetail != undefined?OrderDetail.CustomerName:"")} readOnly={true}/>
                    </div>

                    <div className="col-md-4">
                        <label className="form-label">เบอร์โทร</label>
                        <input type="text" className="form-control" defaultValue={(OrderDetail != undefined?OrderDetail.CustomerMobile:"")} readOnly={true}/>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">วันที่จัดงาน</label>
                        <input type="text" className="form-control" defaultValue={(OrderDetail != undefined?DateThaiFormat(OrderDetail.EventDate):"")} readOnly={true}/>
                    </div>

                    <div className="col-md-4">
                        <label className="form-label">สถานะ</label>
                        <Controller name="OrderStatus" control={control} rules={{required:true}} render={({field})=>(
                            <select className='form-control text-center' {...register("OrderStatus", { required: false })} onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                {[1,2,4,5,6,7].map((v,i)=>{
                                    return <option key={i} value={v}>{OrderStatus(v)}</option>
                                })}
                            </select>
                        )} />
                        {errors.OrderStatus && errors.OrderStatus.type == 'required' && <small className="form-text alert alert-danger">โปรดตรวจสอบ สถานะ</small> }
                    </div>

                    <div className="col-md-8">
                        <label className="form-label">เหตุผล</label>
                        <Controller name="ReasonStatus" control={control} rules={{required:true}} render={({field})=>(
                            <select className='form-control text-center' {...register("ReasonStatus", { required: false })} onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                {ReasonList.map((v,i)=>{
                                return <option key={i} value={v.ReasonId}>{v.Reason}</option>
                            })}
                            </select>
                        )} />
                        {errors.OrderStatus && errors.OrderStatus.type == 'required' && <small className="form-text alert alert-danger">โปรดตรวจสอบ สถานะ</small> }
                    </div>

                    <div className="col-md-12">
                        <label className="form-label">หมายเหตุ</label>
                        <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                            <textarea className="form-control" placeholder="หมายเหตุ" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                        )} />
                        {errors.Remark && errors.Remark.type == 'required' && <small className="form-text alert alert-danger">โปรดระบุที่อยู่</small> }
                    </div>

                    <div className="col-md-2">
                        <label className="form-label">นัดหมาย</label>
                        <select className='form-control' defaultValue={Appointment} onChange={(e)=>{setAppointment(e.target.value)}}>
                            <option value={0} selected={true}>ไม่นัดหมาย</option>
                            <option value={1}>นัดหมาย</option>
                            <option value={2}>นัดชำระเงิน</option>
                            <option value={3}>นัดหมายติดตาม</option>
                        </select>
                    </div>

                    <div className='col-md-2'>
                        <label className="form-label">วันที่นัดครั้งถัดไป</label>
                        <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={AppointmentDate} onChange={(date)=>{setAppointmentDate(date)}} selectsStart startDate={AppointmentDate}/>
                    </div>
                    <div className='col-md-2'>
                        <label className="form-label">ช่วงเวลา</label>
                        <input type="time" className='form-control text-center' value={AppointmentTime} onChange={(e)=>{setAppointmentTime(e.target.value);}}/>

                    </div>
                    <div className='col-md-12 mt-4 text-end'>
                        <button type="submit" className='btn btn-lg btn-success'>บันทึก</button>
                    </div>
                </Form>
            </div>
        </Modal>
        </>)
        
});

export default React.memo(OrderCardStatusInfo)