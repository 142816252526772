import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomerCreate = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [ModalState,setModalState] = useState(false);
    const [BrithDay,setBrithDay] = useState(null);

    const [CustomerListGroupID,setCustomerListGroupID] = useState([]);
    const [CustomerTypeList,setCustomerTypeList] = useState([]);


    const[_ItemSave,setItemSave] = useState([]);
    const[_TotalAmount,setTotalAmount] = useState(0);
    const[_Customer,setCustomer] = useState(null);
    const[TaxInvoice,setTaxInvoice]=useState(false);
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(ContactLogRef){
            setTaxInvoice(false);
            _GetCustomerGroupList();
            _ClearValuAction();
            setModalState(true);
            if(ContactLogRef != undefined && ContactLogRef != null){
                setValue('Mobile',ContactLogRef);
            }
        }
      }));


    const _GetCustomerGroupList = ()=>{
        setCustomerListGroupID([]);
        API({method : "get",url:"/Search/Customer/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setCustomerListGroupID(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetCustomerTypeList = (GroupID)=>{
        setCustomerTypeList([]);
        API({method : "get",url:"/Search/Customer/Group/"+GroupID}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setCustomerTypeList(res.data.Data);
        }).catch(res => {
            return;
        });
    }



    const _Save = async()=>{
        if(getValues('Name') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุชื่อลูกค้า'
            });
            return;
        }
        if(getValues('Mobile') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบเบอร์โทร'
            });
            return;
        }
        /**
        if(getValues('SubdistrictId') == null || getValues('SubdistrictId') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ ตำบล / อำเภอ / จังหวัด'
            });
            return;
        }
        if(getValues('CustomerGroupId') == undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบกลุ่มลูกค้า'
            });
            return;
        }
        if(TaxInvoice){
            if(getValues('TaxName') == undefined){
                Swal.fire({
                    icon: 'error',
                    title: 'โปรดตรวจสอบชื่อออกใบกำกับภาษี'
                });
                return;
            }

            if(getValues('TaxID') == undefined){
                Swal.fire({
                    icon: 'error',
                    title: 'โปรดตรวจสอบประจำ'
                });
                return;
            }
        }
        **/
        let _reqObj = {
            AccountType : (isNaN(Number(getValues('AccountType')))?1:Number(getValues('AccountType'))),
            Name : getValues('Name'),
            NickName : getValues('NickName'),
            Mobile : CleanMobile(getValues('Mobile')),
            Address : getValues('Address'),
            SubdistrictId : getValues('SubdistrictId') === undefined || getValues('SubdistrictId') == null ? 0 : getValues('SubdistrictId'),
            ZipCode : getValues('ZipCode'),
            BrithDay : (BrithDay != null && !isNaN(BrithDay)?DateFormatJSON(BrithDay,false):null),
            CustomerGroupId : getValues('CustomerGroupId'),
            CustomerTypeId : (getValues('CustomerTypeId') == null ? 0:getValues('CustomerTypeId')),
            CompanyName:getValues('CompanyName'),
            CompanyDepartment:getValues('CompanyDepartment'),
            Remark:(getValues('Remark') == undefined ? null : getValues('Remark')),
            TaxInvoice:TaxInvoice,
            TaxName:getValues('TaxName'),
            TaxID:getValues('TaxID'),
            TaxBranchName:getValues('TaxBranchName'),
            TaxAddress:getValues('TaxAddress'),
            TaxZipCode:getValues('TaxZipCode'),
            TaxPhone:getValues('TaxPhone'),
        };
        console.log(_reqObj);
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
 
            API({method : 'post',url:'/Customer/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                
                setModalState(false);
                _ClearValuAction();
                if(props.update != undefined){
                    try{
                        props.update(res.data.Data);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }
    const _ClearValue=()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }
    const _ClearValuAction = ()=>{
        reset();
        setBrithDay(null);
    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">เพิ่มข้อมูลลูกค้า</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>

            <div className='modal-body'>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className='row mt-3'>
                        <div className="col-md-4">
                            <label className="form-label">ชื่อลูกค้า</label>
                            <input type='text' className="form-control" placeholder="ชื่อลูกค้า" autoComplete="off" {...register("Name", { required: false })}/>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">เบอร์โทร</label>
                            <input type='text' className="form-control" placeholder="เบอร์โทร" autoComplete="off" {...register("Mobile", { required: false })}/>
                        </div>

                        <div className="col-md-3">
                            <label className="form-label">ชื่อเล่น</label>
                            <input type='text' className="form-control" placeholder="ชื่อเล่น" autoComplete="off" {...register("NickName", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">วันเกิด</label>
                            <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={BrithDay} onChange={(date)=>{setBrithDay(date)}} selectsStart startDate={BrithDay}/>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-md-2">
                            <label className="form-label">ประเภท</label>
                            <Controller name="AccountType" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-center' onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                    
                                    <option value="1" selected={(field.value == 1 ? true:false)}>บริษัท</option>
                                    <option value="2" selected={(field.value == 2 ? true:false)}>ร้าน</option>
                                </select>
                            )} />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">กลุ่มลูกค้า</label>
                            <Controller name="CustomerGroupId" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-center' onChange={(e)=>{field.onChange(e);_GetCustomerTypeList(e.target.value);}} defaultValue={field.value} >
                                <option value={0}>เลือกกลุ่มลูกค้า</option>
                                {CustomerListGroupID.map((v,i)=>(
                                     <option value={v.CustomerGroupId} key={i}>{v.CustomerGroupName}</option>   
                                ))}
                            </select>
                            )} />

                            

                        </div>
                        <div className="col-md-4">
                            <label className="form-label">ประเภทลูกค้า</label>
                            <Controller name="CustomerTypeId" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-left' onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                    <option value={0}>ไม่ระบุ</option>
                                    {CustomerTypeList.map((v,i)=>(
                                     <option value={v.CustomerTypeId} key={i}>{v.CustomerTypeName}</option>   
                                ))}
                                </select>
                            )} />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">ออกใบกำกับภาษี</label>
                            <button type='button' style={{'width':'100px'}} className={(TaxInvoice?'btn btn-success':'btn btn-secondary')} onClick={(e)=>{if(TaxInvoice) setTaxInvoice(false);else setTaxInvoice(true);}}>{(TaxInvoice?'ต้องการ':'ไม่ต้องการ')}</button>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-6">
                            <label className="form-label">ชื่อบริษัท</label>
                            <input type='text' className="form-control" placeholder="ชื่อบริษัท" autoComplete="off" {...register("CompanyName", { required: false })}/>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">ชื่อหน่วนงาน</label>
                            <input type='text' className="form-control" placeholder="ชื่อหน่วนงาน" autoComplete="off" {...register("CompanyDepartment", { required: false })}/>
                        </div>
                    </div>        
                    <div className='row mt-3'>
                        <div className="col-md-5">
                            <label className="form-label">ที่อยู่</label>
                            <Controller name="Address" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                        <div className="col-md-5">
                            <label className="form-label">ตำบล / อำเภอ / จังหวัด</label>
                            <Controller name="SubdistrictId" control={control} rules={{required:false}} render={({field})=>(
                                <AsyncSelect 
                                    name={field.name}
                                    cacheOptions 
                                    defaultOptions 
                                    placeholder={'ค้นหา ตำบล / อำเภอ / จังหวัด'} 
                                    loadOptions={(e)=>SearchSubDistrict(e)} 
                                    onChange={
                                        (selected) => {
                                            field.onChange(selected.SubdistrictId);
                                        }
                                    }
                                    getOptionLabel={(e)=> e.FullName} 
                                    getOptionValue={(e)=>e.SubdistrictId}


 
                                    
                                    />
                            )} />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">รหัสไปรษณีย์</label>
                            <input type='text' className="form-control" placeholder="รหัสไปรษณีย์" autoComplete="off" {...register("ZipCode", { required: false })}/>
                        </div>
                    </div>     
                    <div className='row mt-4'>
                        <div className="col-md-12">
                            <label className="form-label">หมายเหตุ</label>
                            <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div> 

                    {(TaxInvoice ? <>
                        <div className='row mt-5'>
                        <div className="col-md-6">
                            <label className="form-label">ชื่อออกใบกำกับภาษี</label>
                            <input type='text' className="form-control" placeholder="ชื่อธุรกิจ" autoComplete="off" {...register("TaxName", { required: false })}/>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">เลขประจำตัวผู้เสียภาษี</label>
                            <input type='text' className="form-control" placeholder="เลขประจำตัวผู้เสียภาษี" autoComplete="off" {...register("TaxID", { required: false })}/>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">สำนักงาน/สาขา</label>
                            <input type='text' className="form-control" placeholder="สำนักงาน/สาขา" autoComplete="off" {...register("TaxBranchName", { required: false })}/>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-md-6">
                            <label className="form-label">ที่อยู่ออกใบกำกับภาษี</label>
                            <Controller name="TaxAddress" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">รหัสไปรษณีย์</label>
                            <input type='text' className="form-control" placeholder="รหัสไปรษณีย์" autoComplete="off" {...register("TaxZipCode", { required: false })}/>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">เบอร์โทร</label>
                            <input type='text' className="form-control" placeholder="เบอร์โทร" autoComplete="off" {...register("TaxPhone", { required: false })}/>
                        </div>
                    </div>
                    </>:"")}
                    
                    <div className='row mt-5'>
                        <div className='col-md-12 mt-4 text-end'>
                            <div className='btn-group btn-group-lg'>
                                <button type="button" className='btn btn-danger' onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                <button type="submit" className='btn btn-success'>บันทึก</button>
                            </div>
                        </div>
                    </div>                 
                </Form>
            </div>
        </Modal>
        </>)
        
});

export default React.memo(CustomerCreate)