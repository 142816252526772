import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState, useRef } from 'react';
import API from '../API';
import Swal from 'sweetalert2';
import {DateFormat,DateThaiMonth,DateThai,NumberFormat,MobileFormat, DateThaiFormat} from '../Utils/Function';
import { OrderStatusLabel } from '../Utils/Status';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {  useForm } from "react-hook-form";
import { createRoot } from 'react-dom/client';
import moment from 'moment';

import { DataTable,OrderCardInfo } from '../Components';

const WidgetAppointment = forwardRef((props,ref) =>{
    
    const [_Date,setDate] = useState(new Date());
    const _TableRef = useRef();
    const _OrderCard = useRef();
    const [_Appointment,setAppointment] = useState([]);
    if(props.Date != undefined) setDate(props.Date);
    const { getValues,setValue, control, formState: { errors } } = useForm();

    useEffect(() => {
        (async () => {
            //_GetDate();
        })();
        
        return ()=>{ 
        }
    }, []);

    useImperativeHandle(ref, () => ({
        Load(Date){
            //setDate(Date);
            //_GetDate(Date);
        }
      }));
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 5,
        dom: "frtip",
    }
    const _ajax  = {
        'url': '/Order/Appointment/Summary/Day',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.Date = DateFormat((getValues('Date') === undefined || getValues('Date') === null ? moment().toDate() : getValues('Date')));
        }
    };
    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _OrderInfo(d,r)}>Info</button>
        </div>
    )
    const _column = [
        {data: "AppointmentTime", name: "AppointmentTime", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {data: "AppointmentType", name: "AppointmentType", autoWidth: true,render(data){
            return _AppointmentType(data);
        }},
        { data: "CustomerName", name: "CustomerName", autoWidth: true },
        {data: "CustomerMobile", name: "CustomerMobile", autoWidth: true,render(data){
            return MobileFormat(data);
        }},
        {data: "EventDate", name: "EventDate", autoWidth: true,render(data){
            return DateFormat(data);
        }},
        {data: "OrderStatus", name: "OrderStatus", autoWidth: true,render(data){
            return OrderStatusLabel(data);
        }},
        {
            data: "OrderId", name: "OrderID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _OrderInfo = (OrderID,Row)=>{
        _OrderCard.current?.ShowAppointment(OrderID,Row.AppointmentId);

    }
    const _AppointmentType =(AppointmentType)=>{
        switch(AppointmentType){
            case 1 : return "นัดหมายโทรศัพท์";
            case 2 : return "นัดหมายชำระเงิน";
            case 3 : return "นัดหมายติดตาม";
        }
    }
    const _UpdateTable = ()=>{
        _TableRef.current?.load();
    }
    return (
        <>
        <div className='row'>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <h4>ตารางนัดหมาย ประจำวัน :: {DateThai(_Date)} </h4>
                            </div>
                            <div className='col-lg-4 text-end'>
                                <DatePicker className='form-control text-center' dateFormat={'dd/MM/yyyy'} selected={_Date} onChange={(date)=>{setDate(date);setValue('Date',date);_TableRef.current?.load();}} selectsStart startDate={_Date}/>
                            </div>
                        </div>
                    </div>
                    <div className='card-body' >
                        <div className='row'>
                           <div className='col-lg-12'>
                                <table id="WidgetAppointment" className='table w-100' role='grid'>
                                    <thead>
                                        <tr>
                                            <th className="text-center textcenter">วันที่นัด</th>
                                            <th className="text-center textcenter">ประเภทนัดหมาย</th>
                                            <th className="text-center textcenter">ลูกค้า</th>
                                            <th className="text-center textcenter">เบอร์โทร</th>
                                            <th className="text-center textcenter">วันที่จัดงาน</th>
                                            <th className="text-center textcenter">สถานะ</th>
                                            <th className="text-center no-sort"></th>
                                        </tr>
                                    </thead>
                                    <DataTable TableID={"WidgetAppointment"} Column={_column} Configs={_configs} ref={_TableRef} ajax={_ajax} />
                                </table>
                           </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='row'>
                            <div className='col-lg-12 text-end'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OrderCardInfo ref={_OrderCard} callback={()=>{_UpdateTable();}}/>
        </>)
        
});

export default React.memo(WidgetAppointment)