import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import Swal from 'sweetalert2';
import API from '../../API';
import {QuotationPDF,QuotationImage} from './QuotationUtils';
const QuotationPrintCard = forwardRef((props,ref) =>{
    const [_PDFImageList,setPDFImageList] = useState([]);
    const [_QuotationMode,setQuotationMode] = useState(1);
    const [_DepositeMode,setDepositMode] = useState(1);
    const [Detail,setDetail] = useState(null);
    useEffect(() => {
        (async () => {
            setDetail(props.Detail);
        })();

        return ()=>{ }
    }, []);
   
    const _QuotationPrint = async() =>{
        if(Detail === null) return;
        setPDFImageList([]);
        console.log(_QuotationMode);
        if(_QuotationMode == 1)
            QuotationPDF(Detail.QuotationID);

        if(_QuotationMode == 2){
        
            QuotationImage(Detail.QuotationID).then(r=>{
                console.log(r);
                setPDFImageList(r.Images);
            });
        }

    }
    const _DepositInvoicePDF = async() =>{
        if(Detail === null) return;
        Swal.fire('Please wait')
        Swal.showLoading()
        API({method : "get",url:"/Quotation/"+Detail.QuotationID+"/Deposit/"+_DepositeMode}).then(res=>{
            if (res.status !== 200) {
                Swal.close();
                return;
            }
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                Swal.close();
                return;
            }
             var base64str = res.data.Data.PDF;
             var binary = atob(base64str.replace(/\s/g, ''));
             var len = binary.length;
             var buffer = new ArrayBuffer(len);
             var view = new Uint8Array(buffer);
             for (var i = 0; i < len; i++) {
                 view[i] = binary.charCodeAt(i);
             }
             var blob = new Blob( [view], { type: "application/pdf" });
             var url = URL.createObjectURL(blob);
             window.open(url, '_blank');
             Swal.close();
        }).catch(res => {
            Swal.close();
            return;
        });

    }

    const _InvoicePDF = async() =>{
        if(Detail === null) return;
        Swal.fire('Please wait')
        Swal.showLoading()
        API({method : "get",url:"/Quotation/"+Detail.QuotationID+"/Invoice"}).then(res=>{
            if (res.status !== 200) {
                Swal.close();
                return;
            }
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                Swal.close();
                return;
            }
             var base64str = res.data.Data.PDF;
             var binary = atob(base64str.replace(/\s/g, ''));
             var len = binary.length;
             var buffer = new ArrayBuffer(len);
             var view = new Uint8Array(buffer);
             for (var i = 0; i < len; i++) {
                 view[i] = binary.charCodeAt(i);
             }
             var blob = new Blob( [view], { type: "application/pdf" });
             var url = URL.createObjectURL(blob);
             window.open(url, '_blank');
             Swal.close();
        }).catch(res => {
            Swal.close();
            return;
        });
    }

    return (<>
        <div className='row'>
            <div className='col-12'>
            <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row justify-content-center'>
                                        <div className='col-3'>
                                            <div className='card'>
                                                <div className='card-body' style={{minHeight:'150px'}}>
                                                    <div className='row'>
                                                        <div className='col-12 text-center'>
                                                            <select className='form-control text-center' value={_QuotationMode} onChange={(e)=>{setQuotationMode(e.target.value)}}>
                                                                <option value={1}>PDF</option>
                                                                <option value={2}>รูปภาพ</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        <div className='col-12 text-center'>
                                                            <button className='btn btn-success' onClick={()=>{_QuotationPrint()}}>พิมพ์ใบเสนอราคา</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='card'>
                                                <div className='card-body' style={{minHeight:'150px'}}>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col-12 text-center'>
                                                                    <select className='form-control text-center' value={_DepositeMode} onChange={(e)=>{setDepositMode(e.target.value)}}>
                                                                        <option value={1}>100%</option>
                                                                        <option value={2}>50%</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-4'>
                                                                <div className='col-12 text-center'>
                                                                    <button className='btn btn-success' onClick={()=>{_DepositInvoicePDF()}}>พิมพ์ใบรับมัดจำ</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='card'>
                                                <div className='card-body' style={{minHeight:'150px'}}>
                                                    <div className='row'>
                                                        <div className='col-12 text-center'>
                                                            <button className='btn btn-success' onClick={()=>{_InvoicePDF()}}>พิมพ์ใบเสร็จรับเงิน</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='row mt-4'>
                                        <div className='col-12 '>
                                        {_PDFImageList.map((v,i)=>(
                                            <div className='row justify-content-center' key={i}>
                                                <div className='col-10'>
                                                    <img className='form-control' src={'data:image/jpeg;base64,'+v}/>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
        </div>
    </>)
});

export default React.memo(QuotationPrintCard)